export const SET_USER_STATUS = "SET_USER_STATUS";
export const SET_IS_LOGGED_In = "SET_IS_LOGGED_In";
export const SET_USER = "SET_USER";
export const SET_FILTER = "SET_FILTER";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_POPUP_CLOSE = "SET_POPUP_CLOSE";
export const SET_POPUP_OPEN = "SET_POPUP_OPEN";
export const SET_IS_UPDATE_AVAILABLE = "SET_IS_UPDATE_AVAILABLE";
export const SET_IS_APP_UPDATING = "SET_IS_APP_UPDATING";
export const SET_POPUPS = "SET_POPUPS";
export const SET_DO_IT_LATER = "SET_DO_IT_LATER";
export const SET_NO_DELAY_POPUP = "SET_NO_DELAY_POPUP";
export const SET_INSTANT_POPUP = "SET_INSTANT_POPUP";
export const SET_UPLOAD = "SET_UPLOAD";
export const SET_BACK_COUNT = "SET_BACK_COUNT";
export const GET_PACKAGES = "GET_PACKAGES";
export const GET_KEY = "GET_KEY";
