import {
  createAnimation,
  IonAvatar,
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonList,
  IonLoading,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonSkeletonText,
  IonSlide,
  IonSlides,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonCol, IonRow } from "@ionic/react";
import { IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import {
  arrowBack,
  callSharp,
  closeOutline,
  copy,
  copyOutline,
  heart,
  star,
} from "ionicons/icons";
import {
  createOrder,
  generatePaymentLink,
  getCurrentPackage,
  getSecurityKey,
  getSpecialPackage,
  getSubscriptionList,
  PaymentVerification,
  getVipPackageList,
  autoPackage,
  phonePePaymentVerification,
  isPhonePeOn,
  getContact,
  phonepeStatus,
} from "../../service";
import { Checkout } from "capacitor-razorpay";
import moment from "moment";
import PopUp from "./PopUp";
import payment from "../../assets/images/payment.png";
import qr from "../../assets/images/qr.jpeg";
import qrcode from "../../assets/images/qrcode.jpeg";
import paytm from "../../assets/images/Paytm.png";
import bank from "../../assets/images/bank.png";
import phonepe from "../../assets/images/phonepe.svg";
import googlepay from "../../assets/images/googlepay.webp";
import { CallNumber } from "@awesome-cordova-plugins/call-number";
// import Details from "./Details";
import constant from "../../constant";
import { Clipboard } from "@capacitor/clipboard";
import Lightbox from "react-image-lightbox";
import { Capacitor } from "@capacitor/core";
import starImage from "../../assets/images/star.png";
import ribbin from "../../assets/images/ribbin.png";
import specialgift from "../../assets/images/specialgift.png";
import partyPop from "../../assets/images/partyPop.png";
import heartline from "../../assets/images/heartline.png";
import crownPic from "../../assets/images/crownPic.png";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import { PhonePePaymentPlugin } from "phonepe-payment-capacitor";
import { v4 as uuidv4 } from "uuid";
import sha256 from "crypto-js/sha256";
import { useHistory } from "react-router";
import { async } from "rxjs";
import { useQuery } from "react-query";
const Details = React.lazy(() => import("./Details"));

const Packages: React.FC = () => {
  const sectionType: any = localStorage.getItem("pkgSection");
  const AppContext = useContext(appContext);
  const history = useHistory();
  const [Subscription, setSubscription] = useState([]);
  const [SelectSubscription, setSelectSubscription] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [upgrade, setUpgrade] = useState(false);
  const [upgradeSpecial, setUpgradeSpecial] = useState(false);
  const [upgradeVip, setUpgradeVip] = useState(false);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [helpModal, setHelpModal] = useState<any>(false);
  const [SecurityKey, setSecurityKey] = useState<any>("");
  const [CurrentPackage, setCurrentPackage] = useState<any>(null);
  const [CurrentPackageBasic, setCurrentPackageBasic] = useState<any>(null);
  const [CurrentPackageSpecial, setCurrentPackageSpecial] = useState<any>(null);
  const [CurrentPackageVip, setCurrentPackageVip] = useState<any>(null);
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [callModal, setCallModal] = useState<any>(false);
  const [clickIndex, setClickIndex] = useState<any>(0);
  const [openImage, setOpenImage] = useState<any>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [section, setSection] = useState("basic");
  const [showMoreText, setShowMoreText] = React.useState(false);
  const [selectState, setSelectState] = useState({ status: "basic" });
  const [filter, setFilter] = useState("");
  const [VIPList, setSubscriptionVIP] = useState([]);
  const [specialList, setSubscriptionSpecial] = useState([]);
  const [autoPackageDataBasic, setAutoPackageDataBasic] = useState<any>([]);
  const [autoPackageDataSpecial, setAutoPackageDataSpecial] = useState<any>([]);
  const [autoPackageDataVip, setAutoPackageDataVip] = useState<any>([]);
  const [scaledCardOne, setScaledCardOne] = useState(null);
  const [scaledCardTwo, setScaledCardTwo] = useState(null);
  const [scaledCardThree, setScaledCardThree] = useState(null);
  const [scaledCardFour, setScaledCardFour] = useState(null);
  const [showBasicPaymentModal, setShowBasicPaymentModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [Before, setBefore] = useState("");
  const [After, setAfter] = useState("");
  const [isPhonePe, setIsPhonePe] = useState<any>(false);

  useEffect(() => {
    goTo();
  }, []);
  const {
    isLoading: isLoadingCurrent,
    error: errorCurrent,
    data: dataCurrent,
    refetch,
  } = useQuery("packageCurrent", getCurrentPackage);
  const [pkgNameSpecial, setPkgNameSpecial] = useState<any>({
    pkgMonth: "",
    price: "",
    i: "",
  });
  const [error, setError] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [errorVip, setVipError] = useState<any>(false);

  useEffect(() => {
    console.log("::::", AppContext?.user?.payment_verified_at);
    if (AppContext?.user?.payment_verified_at !== 1 && !dataCurrent) return;
    const getPackagesByType = (type: any) =>
      dataCurrent?.data?.data?.find((pkg: any) => pkg?.types === type) || null;
    const paymentVerified = AppContext?.user?.payment_verified_at === 1;
    setCurrentPackage(dataCurrent?.data?.data);
    setCurrentPackageBasic(
      (getPackagesByType("basic") ||
        getPackagesByType(null) ||
        getPackagesByType("null") ||
        getPackagesByType("Basic")) &&
        paymentVerified
        ? getPackagesByType("basic") ||
            getPackagesByType(null) ||
            getPackagesByType("null") ||
            getPackagesByType("Basic")
        : null
    );

    setCurrentPackageSpecial(
      getPackagesByType("special") || getPackagesByType("Special")
        ? getPackagesByType("special") || getPackagesByType("Special")
        : null
    );

    setCurrentPackageVip(
      getPackagesByType("VIP") && paymentVerified
        ? getPackagesByType("VIP")
        : null
    );
  }, [isLoadingCurrent, dataCurrent]);

  useEffect(() => {
    if (sectionType === "VIP") {
      setSection("VIP");
      setSelectState({ ...selectState, status: "vip" });
    } else if (sectionType === "Special") {
      setSection("special");
      setSelectState({ ...selectState, status: "special" });
    } else {
      setSection("basic");
      setSelectState({ ...selectState, status: "basic" });
    }
  }, [sectionType]);

  useIonViewWillLeave(() => {
    if (CurrentPackageBasic?.types === "basic") {
      setUpgrade(true);
    }
  });

  const getIsPhonePeOn = async () => {
    const res = await isPhonePeOn();
    setIsPhonePe(res?.data?.data?.is_payment_method);
  };

  const getContactList = useCallback(async () => {
    const res = await getContact();
    setBefore(res?.data?.data?.conatct[0]?.mobile_number);
    setAfter(res?.data?.data?.conatct[1]?.mobile_number);
  }, [AppContext?.user?.payment_verified_at]);

  // const CurrentPackageData = useCallback(async () => {
  //   var res: any = await getCurrentPackage();
  //   if (res?.data?.status) {
  //     setCurrentPackage(res?.data?.data);

  //     const getPackagesByType = (type: any) =>
  //       res?.data?.data?.find((pkg: any) => pkg?.types === type) || null;

  //     const paymentVerified = AppContext?.user?.payment_verified_at === 1;

  //     setCurrentPackageBasic(
  //       (getPackagesByType("basic") ||
  //         getPackagesByType(null) ||
  //         getPackagesByType("null") ||
  //         getPackagesByType("Basic")) &&
  //         paymentVerified
  //         ? getPackagesByType("basic") ||
  //             getPackagesByType(null) ||
  //             getPackagesByType("null") ||
  //             getPackagesByType("Basic")
  //         : null
  //     );

  //     setCurrentPackageSpecial(
  //       getPackagesByType("special") || getPackagesByType("Special")
  //         ? getPackagesByType("special") || getPackagesByType("Special")
  //         : null
  //     );

  //     setCurrentPackageVip(
  //       getPackagesByType("VIP") && paymentVerified
  //         ? getPackagesByType("VIP")
  //         : null
  //     );
  //   } else {
  //     setCurrentPackage(null);
  //   }
  // }, [AppContext?.user?.payment_verified_at]);

  useEffect(() => {
    // goTo();
    // if (AppContext?.user?.payment_verified_at !== 1) {
    //   setCurrentPackageBasic(null);
    //   setCurrentPackageSpecial(null);
    //   setCurrentPackageVip(null);
    //   setCurrentPackage(null);
    // }
  }, [isLoadingCurrent, AppContext?.user?.payment_verified_at]);

  const getAutoOffer = useCallback(async () => {
    try {
      const res = await autoPackage();
      if (res.data.status && !AppContext?.user?.payment_verified_at) {
        const getPackagesByType = (type: any) =>
          res?.data?.data?.filter((pkg: any) => pkg?.package?.types === type) ||
          [];
        const splPkg =
          res?.data?.data?.filter(
            (pkg: any) => pkg?.package?.types === "special"
          )?.length > 0
            ? res?.data?.data?.filter(
                (pkg: any) => pkg?.package?.types === "special"
              )
            : res?.data?.data?.filter(
                (pkg: any) => pkg?.package?.types === "Special"
              )?.length > 0
            ? res?.data?.data?.filter(
                (pkg: any) => pkg?.package?.types === "Special"
              )
            : [];
        const dataBasic =
          (getPackagesByType("basic") ||
            getPackagesByType(null) ||
            getPackagesByType("null") ||
            getPackagesByType("Basic")) ??
          [];
        setAutoPackageDataBasic(dataBasic);
        setAutoPackageDataSpecial(splPkg);
        setAutoPackageDataVip(getPackagesByType("VIP") ?? []);
        var resss = await AppContext?.key;
        setSecurityKey(resss);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useIonViewWillEnter(() => {
    if (CurrentPackageBasic !== null) {
      setUpgrade(false);
    }
    if (sectionType === "VIP") {
      setSection("VIP");
      setSelectState({ ...selectState, status: "vip" });
    } else if (sectionType === "Special") {
      setSection("special");
      setSelectState({ ...selectState, status: "special" });
    } else {
      setSection("basic");
      setSelectState({ ...selectState, status: "basic" });
    }
  });

  useEffect(() => {
    if (CurrentPackageBasic !== null) {
      setUpgrade(false);
    }
    setIsPhonePe(localStorage.getItem("IsPhonePeOnData"));
  }, []);

  const toggleCardOneScale = (id: any) => {
    setScaledCardOne(id === scaledCardOne ? null : id);
    setScaledCardTwo(null);
    setScaledCardThree(null);
    setScaledCardFour(null);
  };
  const toggleCardTwoScale = (id: any) => {
    setScaledCardTwo(id === scaledCardTwo ? null : id);
    setScaledCardOne(null);
    setScaledCardThree(null);
    setScaledCardFour(null);
  };
  const toggleCardThreeScale = (id: any) => {
    setScaledCardThree(id === scaledCardThree ? null : id);
    setScaledCardOne(null);
    setScaledCardTwo(null);
    setScaledCardFour(null);
  };

  const getSubscription = async () => {
    var res: any = await getSubscriptionList();
    if (res?.data?.status) {
      let pkg: any = res?.data?.data?.filter((pakg: any) => !pakg?.view_key);
      setLoading(false);
      res?.data?.data?.map((val: any, i: any) => {
        var link = AppContext?.user?.payment_link?.find((l: any) => {
          if (l.pkgId == val.id) return l;
        });
        if (link) res.data.data[i].payment_link = link;
        else res.data.data[i].payment_link = null;
      });
      setSubscription(pkg);
    }
    var resss = await AppContext?.key;
    setSecurityKey(resss);
  };

  const vipPackageList = async () => {
    let res = await getVipPackageList();
    if (res?.data?.status) {
      setLoading(false);
      let pkg: any = res?.data?.data?.filter((pakg: any) => !pakg?.view_key);
      res?.data?.data?.map((val: any, i: any) => {
        var link = AppContext?.user?.payment_link?.find((l: any) => {
          if (l.pkgId == val.id) return l;
        });
        if (link) res.data.data[i].payment_link = link;
        else res.data.data[i].payment_link = null;
      });
      setSubscriptionVIP(pkg);
    }
    var resss = await AppContext?.key;
    setSecurityKey(resss);
  };

  const specialPackageList = async () => {
    let res = await getSpecialPackage(AppContext?.user?.id);
    if (res?.data?.status) {
      setLoading(false);
      let pkg: any = res?.data?.data?.filter((pakg: any) => !pakg?.view_key);
      res?.data?.data?.map((val: any, i: any) => {
        var link = AppContext?.user?.payment_link?.find((l: any) => {
          if (l.pkgId == val.id) return l;
        });
        if (link) res.data.data[i].payment_link = link;
        else res.data.data[i].payment_link = null;
      });
      setSubscriptionSpecial(pkg);
    }
    var resss = await AppContext?.key;
    setSecurityKey(resss);
  };

  const verifyPayment = async (data: any, i: any) => {
    var obj = {
      amount: AppContext.user.no_offer_for_user ? i?.mrp : i?.price,
      package_id: i?.id,
      razorpay_payment_id: data?.response?.razorpay_payment_id,
      razorpay_order_id: data?.response?.razorpay_order_id,
      razorpay_signature: data?.response?.razorpay_signature,
      package_month: i?.month,
    };
    var res: any = await PaymentVerification(obj);
    console.log("verifyAPIFDONE ::::");
    if (res?.data?.status) {
      toast("Paid Amount Succcessfully");
      refetch();
      AppContext.setPopups(constant.popups.isSubscribe, true, true);
      setOpenPopUp(true);
    }
  };

  const generateLink = async (i: any) => {
    setShowLoading(true);
    var obj = {
      amount: i?.price,
      package_id: i?.id,
    };
    var res = await generatePaymentLink(obj);
    if (res?.data?.status) {
      setShowLoading(false);
      AppContext.updateUser();
      toast(
        `Payment link generated Succcessfully for  ${i?.name?.english} package`
      );
      getSubscription();
      vipPackageList();
      specialPackageList();
      getAutoOffer();
    }
  };

  const payNow = async (i: any) => {
    if (isPhonePe && Capacitor.getPlatform() === "android") {
      const transactionId =
        `JSM-${i?.id}-${
          AppContext.user.no_offer_for_user ? i?.mrp : i?.price
        }-` + uuidv4().toString().slice(-6);
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: "JAINSHADIONLINE",
          merchantTransactionId: transactionId,
          merchantUserId: "JSM-USERAPP",
          amount: AppContext.user.no_offer_for_user
            ? i?.mrp * 100
            : i?.price * 100,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: AppContext?.user?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody +
          "/pg/v1/pay" +
          "a895e5f3-339d-4a2e-8904-e604235c3d65"
      );
      const checksum = checksumUrl + "###" + 1;
      const xVerify =
        sha256(
          `/pg/v1/status/JAINSHADIONLINE/${transactionId}a895e5f3-339d-4a2e-8904-e604235c3d65`
        ) + "###1";

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          const response = await phonepeStatus({ transactionId, xVerify });
          if (response?.data?.data?.state === "COMPLETED") {
            let obj = {
              package_id: i?.id,
              amount: AppContext.user.no_offer_for_user ? i?.mrp : i?.price,
              package_month: i?.month,
              payment_type: "phone_pay",
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            if (res?.data?.status) {
              let status = true;
              window.location.assign(
                `/app/transaction/${transactionId}/${status}`
              );
            }
          } else if (
            response?.data?.data?.state === "PENDING" ||
            response?.data?.data?.state === "FAILED"
          ) {
            let status = false;
            window.location.assign(
              `/app/transaction/${transactionId}/${status}`
            );
          }
        })
        .catch(async (transactionError: any) => {
          let status = false;
          window.location.assign(`/app/transaction/${transactionId}/${status}`);
        });
    } else {
      var res = await createOrder({
        amount: AppContext.user.no_offer_for_user ? i?.mrp : i?.price,
      });
      if (res?.data?.status) {
        const options = {
          key: SecurityKey,
          amount: AppContext.user.no_offer_for_user ? i?.mrp : i?.price,
          order_id: res?.data?.data?.id,
          currency: "INR",
          name: AppContext?.user?.nameEnglish,
          prefill: {
            email: "info@jainshadimilan.com",
            contact: AppContext?.user?.mobile,
          },
          theme: {
            color: "#3399cc",
          },
        };
        try {
          let data = await Checkout.open(options);
          verifyPayment(data, i);
        } catch (error: any) {
          let errorObj = JSON.parse(error["code"]);
          alert(errorObj.description);
        }
      }
    }
  };

  const payNowSpecialVip = async (i: any, profile: any, month: any) => {
    if (isPhonePe && Capacitor.getPlatform() === "android") {
      const transactionId =
        `JSM-${profile?.id}-${i}-` + uuidv4().toString().slice(-6);
      const base64RequestBody = btoa(
        JSON.stringify({
          merchantId: "JAINSHADIONLINE",
          merchantTransactionId: transactionId,
          merchantUserId: "JSM-USERAPP",
          amount: i * 100,
          callbackUrl: `${constant.ImageS3URl}/app/transaction/${transactionId}`,
          mobileNumber: AppContext?.user?.mobile,
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        })
      );

      const checksumUrl = sha256(
        base64RequestBody +
          "/pg/v1/pay" +
          "a895e5f3-339d-4a2e-8904-e604235c3d65"
      );
      const checksum = checksumUrl + "###" + 1;
      const xVerify =
        sha256(
          `/pg/v1/status/JAINSHADIONLINE/${transactionId}a895e5f3-339d-4a2e-8904-e604235c3d65`
        ) + "###1";

      PhonePePaymentPlugin.startTransaction({
        body: base64RequestBody,
        checksum: checksum,
        packageName: "com.phonepe.app",
        appSchema: "",
      })
        .then(async (transactionResult: any) => {
          const response = await phonepeStatus({ transactionId, xVerify });
          if (response?.data?.data?.state === "COMPLETED") {
            let obj = {
              package_id: profile?.id,
              amount: i,
              payment_type: "phone_pay",
            };
            const res = await phonePePaymentVerification(
              AppContext?.user?.id,
              obj
            );
            if (res?.data?.status) {
              let status = true;
              window.location.assign(
                `/app/transaction/${transactionId}/${status}`
              );
            }
          } else if (
            response?.data?.data?.state === "PENDING" ||
            response?.data?.data?.state === "FAILED"
          ) {
            let status = false;
            window.location.assign(
              `/app/transaction/${transactionId}/${status}`
            );
          }
        })
        .catch(async (transactionError: any) => {
          let status = false;
          window.location.assign(`/app/transaction/${transactionId}/${status}`);
        });
    } else {
      var res = await createOrder({ amount: i });
      if (res?.data?.status) {
        const options = {
          key: SecurityKey,
          amount: i,
          order_id: res?.data?.data?.id,
          currency: "INR",
          name: AppContext?.user?.nameEnglish,
          prefill: {
            email: "info@jainshadimilan.com",
            contact: AppContext?.user?.mobile,
          },
          theme: {
            color: "#3399cc",
          },
        };
        try {
          let data = await Checkout.open(options);
          verifyPayment(data, profile);
        } catch (error: any) {
          let errorObj = JSON.parse(error["code"]);
          alert(errorObj.description);
        }
      }
    }
  };

  var tempDateBasic = moment(AppContext?.user?.payment_verified_date).add(
    CurrentPackageBasic?.package_month,
    "months"
  );
  var tempDateSpecial = moment(AppContext?.user?.payment_verified_date).add(
    CurrentPackageSpecial?.package_month,
    "months"
  );
  var tempDateVip = moment(AppContext?.user?.payment_verified_date).add(
    CurrentPackageVip?.package_month,
    "months"
  );
  var planDateBasic = tempDateBasic.diff(
    AppContext?.user?.payment_verified_date,
    "days"
  );
  var planDateSpecial = tempDateSpecial.diff(
    AppContext?.user?.payment_verified_date,
    "days"
  );
  var planDateVip = tempDateVip.diff(
    AppContext?.user?.payment_verified_date,
    "days"
  );

  const goTo = async () => {
    setOpenPopUp(false);
    await AppContext.updateUser();
    refetch();
    if (localStorage.getItem("activeUser")) {
      setOpenDetail(true);
    }
  };

  const modal = useRef<HTMLIonModalElement>(null);

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation()
      .addElement(root?.querySelector("ion-backdrop")!)
      .fromTo("opacity", "0.01", "var(--backdrop-opacity)");

    const wrapperAnimation = createAnimation()
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "scale(0)" },
        { offset: 1, opacity: "0.99", transform: "scale(1)" },
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing("ease-out")
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  const leaveAnimation = (baseEl: HTMLElement) => {
    return enterAnimation(baseEl).direction("reverse");
  };

  const onDidDismiss = async () => {
    if (
      CurrentPackage === null &&
      AppContext?.user?.payment_verified_at !== 1 &&
      clickIndex === 0
    ) {
      setCallModal(true);
      AppContext.setPopups(constant.popups.isSubscribe, true);
    } else {
      AppContext.setPopups(constant.popups.isSubscribe, false);
      setUpgrade(false);
      setSelectSubscription(null);
    }
    if (clickIndex === 1) {
      AppContext.setPopups(constant.popups.isSubscribe, false);
      setUpgrade(false);
      setSelectSubscription(null);
    }
    if (CurrentPackageBasic !== null) {
      setUpgrade(false);
    }
    setCurrentPackageBasic(null);
    setCurrentPackageSpecial(null);
    setCurrentPackageVip(null);
    setCurrentPackage(null);
  };

  const onWillDismiss = async () => {
    if (clickIndex === 1) {
      AppContext.setPopups(constant.popups.isSubscribe, false);
    } else {
      AppContext.setPopups(constant.popups.isSubscribe, false);
    }
    setCurrentPackageBasic(null);
    setCurrentPackageSpecial(null);
    setCurrentPackageVip(null);
    setCurrentPackage(null);
  };
  const writeToClipboard = async (key: any) => {
    await Clipboard.write({
      string: key,
    });
    toast("Copied Successfully.");
  };

  const checkClipboard = async () => {
    const { type, value } = await Clipboard.read();
  };

  const writeToClipboardPaymentLink = async (link: any) => {
    await Clipboard.write({
      string: link,
    });
    toast("Link Copied Successfully.");
  };

  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <div style={{ display: "flex" }}>
        <IonBadge style={{ width: "39px" }}>
          <h4 style={{ marginBottom: "0", fontWeight: "600" }}>{days}</h4>
        </IonBadge>
        <div className="offerEndTimedot">: </div> <br />
        <IonBadge style={{ width: "34px" }}>
          <h4 style={{ marginBottom: "0", fontWeight: "600" }}>{hours}</h4>
        </IonBadge>
        <div className="offerEndTimedot">: </div>
        <IonBadge style={{ width: "34px" }}>
          <h4 style={{ marginBottom: "0", fontWeight: "600" }}>{minutes}</h4>
        </IonBadge>
        <div className="offerEndTimedot">: </div>
        <IonBadge style={{ width: "34px" }}>
          <h4 style={{ marginBottom: "0", fontWeight: "600" }}>{seconds}</h4>
        </IonBadge>
      </div>
    );
  };

  useEffect(() => {
    if (AppContext?.user?.payment_verified_at === 1) {
      if (CurrentPackage?.types === null) {
        setUpgradeSpecial(true);
        setUpgradeVip(true);
      } else if (CurrentPackage?.types === "Special") {
        setUpgrade(true);
        setUpgradeVip(true);
      } else if (CurrentPackage?.types === "VIP") {
        setUpgrade(true);
        setUpgradeSpecial(true);
      }
    }
  }, [section]);

  const handleSwitchClick = () => {
    setShowBasicPaymentModal(!showBasicPaymentModal);
  };

  const slideOpts = {
    // initialSlide: 3,
    // speed: 400,
    slidesPerView: 2.2,
    spaceBetween: 10,
  };

  const onWhatsAppMessage = () => {
    let mobile = AppContext?.user?.payment_verified_at ? After : Before;
    window.location.href = "https://wa.me/91" + `${String(mobile)}`;
    // window.location.href = "https://wa.me/91" + `${"7610447511"}`;
  };

  // console.log("autoPackageDataBasic :::", autoPackageDataBasic);
  // console.log("autoPackageDataSpecial :::", autoPackageDataSpecial);
  // console.log("autoPackageDataVip :::", autoPackageDataVip);

  const onJsmCall = async () => {
    let mobile = AppContext?.user?.payment_verified_at ? After : Before;
    // await CallNumber.callNumber("7610447511", true);
    await CallNumber.callNumber(String(mobile), true);
  };

  const onDidPresent = async () => {
    setClickIndex(0);
    await goTo();
    getSubscription();
    vipPackageList();
    specialPackageList();
    getAutoOffer();
    getIsPhonePeOn();
    getContactList();
  };

  return (
    <div className="packageBody">
      <IonModal
        isOpen={AppContext.popups[constant.popups.isSubscribe]}
        onDidDismiss={onDidDismiss}
        onWillDismiss={onWillDismiss}
        onDidPresent={onDidPresent}
        onWillPresent={async () => {
          localStorage.setItem("isModal", "true");
        }}
        // onIonModalDidPresent={async () => {

        // }}
      >
        <IonHeader>
          <IonToolbar color={"primary"}>
            <IonTitle className="heading text-white">Packages</IonTitle>
            <div slot="end" className="backBtn">
              <IonButton
                type="submit"
                className="px-1"
                onClick={(event) => {
                  localStorage.setItem("pkgSection", "basic");
                  setShowBasicPaymentModal(false);
                  event.stopPropagation();
                  if (
                    CurrentPackage === null &&
                    AppContext?.user?.payment_verified_at !== 1 &&
                    clickIndex === 0
                  ) {
                    setCallModal(true);
                  } else {
                    AppContext.setPopups(constant.popups.isSubscribe, false);
                    setUpgrade(false);
                    setSelectSubscription(null);
                  }

                  if (clickIndex === 1) {
                    AppContext.setPopups(constant.popups.isSubscribe, false);
                    setUpgrade(false);
                    setSelectSubscription(null);
                  }
                }}
              >
                <IonIcon icon={closeOutline} color="light" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-1">
            <IonLoading
              isOpen={showLoading}
              onDidDismiss={() => setShowLoading(false)}
              message={"Loading..."}
              duration={5000}
            />
            <h4
              className="ion-text-center fs-18 m-2"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              Pay Now to Contact Matches...
            </h4>
            <IonCard
              className="bg-none mb-3 mt-0 ion-text-center"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <IonButton
                size="small"
                className="outline-btn fs-14 font-weight-500"
                fill={selectState.status === "basic" ? "solid" : "outline"}
                color="primary"
                onClick={() => {
                  setSelectState({ ...selectState, status: "basic" });
                  setSection("basic");
                }}
              >
                <div style={{ height: "38px", width: "38px" }}>
                  <img
                    loading="lazy"
                    src={ribbin}
                    style={{
                      maxHeight: 24,
                      objectFit: "cover",
                      marginTop: "6px",
                    }}
                  />
                </div>
                <p
                  className="font-weight-600"
                  style={{
                    color: selectState.status === "basic" ? "white" : "black",
                    marginTop: "12px",
                  }}
                >
                  Basic
                </p>{" "}
              </IonButton>
              <IonButton
                size="small"
                className="outline-btn ml-10 fs-14 font-weight-500"
                fill={selectState.status === "special" ? "solid" : "outline"}
                color="primary"
                onClick={() => {
                  setSelectState({ ...selectState, status: "special" });
                  setFilter("special");
                  setSection("special");
                }}
              >
                <div style={{ height: "38px", width: "38px" }}>
                  <img
                    loading="lazy"
                    src={specialgift}
                    style={{
                      maxHeight: 24,
                      objectFit: "cover",
                      marginTop: "6px",
                    }}
                  />
                </div>
                <p
                  className="font-weight-600"
                  style={{
                    color: selectState.status === "special" ? "white" : "black",
                    marginTop: "12px",
                  }}
                >
                  Special
                </p>{" "}
              </IonButton>
              <IonButton
                size="small"
                className="outline-btn ml-10 fs-14 font-weight-500"
                fill={selectState.status === "vip" ? "solid" : "outline"}
                color="primary"
                onClick={() => {
                  setSelectState({ ...selectState, status: "vip" });
                  setFilter("VIP");
                  setSection("VIP");
                }}
              >
                <div style={{ height: "38px", width: "38px" }}>
                  <img
                    loading="lazy"
                    src={crownPic}
                    style={{
                      maxHeight: 24,
                      objectFit: "cover",
                      marginTop: "6px",
                    }}
                  />
                </div>
                <p
                  className="font-weight-600"
                  style={{
                    color: selectState.status === "vip" ? "white" : "black",
                    marginTop: "12px",
                  }}
                >
                  VIP
                </p>{" "}
              </IonButton>
            </IonCard>

            {CurrentPackageBasic !== null &&
              section === "basic" &&
              AppContext?.user?.payment_verified_at === 1 && (
                <IonCard className="card subscriptionCardParent ion-text-center">
                  <p className="primary-text p-2 mb-0" style={{ fontSize: 12 }}>
                    {CurrentPackageBasic?.name?.english === "Platinum" ? (
                      <b> Your plan till Marriage.</b>
                    ) : (
                      <b>
                        {" "}
                        Your plan will be expire within {planDateBasic} days.
                      </b>
                    )}
                  </p>
                </IonCard>
              )}
            {CurrentPackageSpecial !== null && section === "special" && (
              <IonCard className="card subscriptionCardParent ion-text-center">
                <p className="primary-text p-2 mb-0" style={{ fontSize: 12 }}>
                  <b>
                    {" "}
                    Your plan will be expire within {planDateSpecial} days.
                  </b>
                </p>
              </IonCard>
            )}
            {CurrentPackageVip !== null &&
              section === "VIP" &&
              AppContext?.user?.payment_verified_at === 1 && (
                <IonCard className="card subscriptionCardParent ion-text-center">
                  <p className="primary-text p-2 mb-0" style={{ fontSize: 12 }}>
                    <b> Your plan will be expire within {planDateVip} days.</b>
                  </p>
                </IonCard>
              )}

            {/* Basic Current Package */}
            {CurrentPackageBasic !== null &&
            section === "basic" &&
            AppContext?.user?.payment_verified_at === 1 ? (
              <IonCard className="card subscriptionCardParent">
                <h4
                  className="ion-text-center fs-18 m-2"
                  style={{ color: "#000" }}
                >
                  My Current Plan
                </h4>
                <IonItem className="subscriptionCard">
                  <IonAvatar className="icon-box">
                    <IonIcon
                      color="primary"
                      size="large"
                      className="fs-18"
                      icon={star}
                    />
                  </IonAvatar>
                  <IonLabel>
                    <h3>
                      <b>{CurrentPackageBasic?.name?.english}</b>
                    </h3>
                    <p>
                      ₹{CurrentPackageBasic?.amount}{" "}
                      {CurrentPackageBasic?.package_month > 59
                        ? "Till Marriage"
                        : "for " +
                          CurrentPackageBasic?.package_month +
                          " months"}{" "}
                    </p>
                  </IonLabel>
                </IonItem>
                <IonLabel
                  className="subscriptionDesc"
                  dangerouslySetInnerHTML={{
                    __html: CurrentPackageBasic?.description?.english,
                  }}
                ></IonLabel>
                <div className="btnFill p-3">
                  <IonButton
                    type="submit"
                    expand="block"
                    onClick={() => {
                      if (CurrentPackageBasic?.name?.english === "Platinum") {
                        setSection("special");
                        setSelectState({ ...selectState, status: "special" });
                      } else {
                        setUpgrade(true);
                      }
                    }}
                  >
                    Upgrade Plan Now
                  </IonButton>
                </div>
              </IonCard>
            ) : null}
            {loading ? (
              [...Array(10)].map((user) => (
                <IonCard className="subscriptionCardParent">
                  <IonItem>
                    <IonThumbnail className="ion-margin-end">
                      <IonSkeletonText animated></IonSkeletonText>
                    </IonThumbnail>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "50%" }}
                        ></IonSkeletonText>
                      </h3>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "40%" }}
                        ></IonSkeletonText>
                      </p>
                    </IonLabel>
                    <IonLabel slot="end">
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "100%" }}
                        ></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                </IonCard>
              ))
            ) : (
              <>
                {Subscription?.length < 1 && CurrentPackageBasic?.name?.english !== "Platinum" ? (
                  <IonCard className="card ">
                    {" "}
                    <p className="fs-16 ion-text-center ion-margin-top">
                      No New Subscription !
                    </p>
                  </IonCard>
                ) : (
                  <>
                    {CurrentPackageBasic !== null
                      ? upgrade &&
                        section === "basic" && (
                          <div>
                            {autoPackageDataBasic?.length === 0
                              ? Subscription?.map((i: any) => {
                                  const expirationDate: any = new Date(
                                    new Date(i?.offerExpireDate).getTime() -
                                      (5 * 60 + 30) * 60 * 1000
                                  );
                                  const isExpired =
                                    expirationDate <= Date.now();

                                  return (
                                    <>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "center",
                                          top: "10px",
                                        }}
                                      >
                                        <div
                                          className="ms-3"
                                          style={{
                                            position: "absolute",
                                            top: "-3px",
                                            zIndex: "2",
                                          }}
                                        >
                                          {i?.is_top_seller && (
                                            <IonBadge
                                              slot="end"
                                              color="success"
                                            >
                                              Top seller
                                            </IonBadge>
                                          )}
                                          {i?.is_top_value && (
                                            <IonBadge
                                              slot="end"
                                              color="success"
                                            >
                                              Top value
                                            </IonBadge>
                                          )}
                                        </div>
                                        <IonCard
                                          className="subscriptionCardParent"
                                          onClick={() => {
                                            setShowMoreText(false);
                                            setSelectSubscription(i);
                                          }}
                                          style={{
                                            border:
                                              SelectSubscription?.id ===
                                                i?.id && "1px solid #ee453d",
                                          }}
                                        >
                                          <IonRow>
                                            <IonCol size="12">
                                              <div className="offerCard">
                                                <IonItem className="subscriptionCard">
                                                  <IonAvatar className="icon-box">
                                                    <img
                                                      loading="lazy"
                                                      src={starImage}
                                                      style={{
                                                        minHeight: 2,
                                                        maxHeight: 50,
                                                        objectFit: "cover",
                                                        width: "92%",
                                                      }}
                                                    />
                                                  </IonAvatar>
                                                  <IonLabel
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      <b>{i?.name?.english}</b>
                                                    </h3>
                                                    <p
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.month > 59
                                                        ? "Till Marriage"
                                                        : i?.month +
                                                          " months"}{" "}
                                                    </p>
                                                  </IonLabel>
                                                  {CurrentPackageBasic ===
                                                  null ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {AppContext.user
                                                          .no_offer_for_user ? (
                                                          <h3>
                                                            <b>₹{i?.mrp}.00 </b>
                                                            <br />
                                                            <b>0% off</b>
                                                          </h3>
                                                        ) : (
                                                          <h3>
                                                            {parseFloat(
                                                              (
                                                                ((i?.mrp -
                                                                  i?.price) /
                                                                  i?.mrp) *
                                                                100
                                                              ).toFixed(1)
                                                            )}
                                                            % OFF <br />
                                                            {i?.mrp !==
                                                              i?.price && (
                                                              <>
                                                                <s>
                                                                  ₹{i?.mrp}.00{" "}
                                                                </s>{" "}
                                                                <br />{" "}
                                                              </>
                                                            )}
                                                            <b
                                                              style={{
                                                                color:
                                                                  "#ffffff",
                                                              }}
                                                            >
                                                              ₹
                                                              {i?.mrp !==
                                                              i?.price
                                                                ? i?.price
                                                                : i?.mrp}
                                                              .00{" "}
                                                            </b>
                                                          </h3>
                                                        )}
                                                      </h3>
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english ===
                                                      "Platinum" &&
                                                    i?.name?.english ===
                                                      "Platinum" ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {AppContext.user
                                                        .no_offer_for_user ? (
                                                        <h3>
                                                          <b>₹{i?.mrp}.00 </b>
                                                          <br />
                                                          <b>0% off</b>
                                                        </h3>
                                                      ) : (
                                                        <h3>
                                                          <b
                                                            style={{
                                                              color: "#ffffff",
                                                            }}
                                                          >
                                                            ₹{i?.mrp}.00{" "}
                                                          </b>
                                                        </h3>
                                                      )}
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english === "Gold" &&
                                                    i?.name?.english ===
                                                      "Platinum" ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.mrp -
                                                              i?.price) /
                                                              i?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.mrp !==
                                                          i?.price && (
                                                          <>
                                                            <s>₹{i?.mrp}.00 </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.mrp !== i?.price
                                                            ? i?.price
                                                            : i?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english === "Silver" &&
                                                    (i?.name?.english ===
                                                      "Platinum" ||
                                                      i?.name?.english ===
                                                        "Gold") ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.mrp -
                                                              i?.price) /
                                                              i?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.mrp !==
                                                          i?.price && (
                                                          <>
                                                            <s>₹{i?.mrp}.00 </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.mrp !== i?.price
                                                            ? i?.price
                                                            : i?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    </IonLabel>
                                                  ) : (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {AppContext.user
                                                        .no_offer_for_user ? (
                                                        <h3>
                                                          <b>₹{i?.mrp}.00 </b>
                                                          <br />
                                                          <b>0% off</b>
                                                        </h3>
                                                      ) : (
                                                        <h3>
                                                          <b
                                                            style={{
                                                              color: "#ffffff",
                                                            }}
                                                          >
                                                            ₹{i?.mrp}.00{" "}
                                                          </b>
                                                        </h3>
                                                      )}
                                                    </IonLabel>
                                                  )}
                                                </IonItem>
                                              </div>

                                              <div>
                                                {!isExpired &&
                                                  new Date(
                                                    i?.offerExpireDate
                                                  ).getTime() > 0 && (
                                                    <IonCard className="offerEnd px-3">
                                                      <div>
                                                        <IonLabel>
                                                          <h1>OFFER</h1>
                                                          <IonLabel></IonLabel>
                                                          <h2
                                                            style={{
                                                              fontSize: "18px",
                                                            }}
                                                          >
                                                            ENDS IN
                                                          </h2>
                                                        </IonLabel>
                                                      </div>
                                                      <div>
                                                        <IonBadge
                                                          style={{
                                                            backgroundColor:
                                                              "#fcf6f6",
                                                          }}
                                                        >
                                                          <h4
                                                            style={{
                                                              marginBottom: "0",
                                                              fontWeight: "600",
                                                              fontSize: "18px",
                                                            }}
                                                          >
                                                            <Countdown
                                                              date={
                                                                new Date(
                                                                  new Date(
                                                                    i?.offerExpireDate
                                                                  ).getTime() -
                                                                    (5 * 60 +
                                                                      30) *
                                                                      60 *
                                                                      1000
                                                                )
                                                              }
                                                              renderer={
                                                                renderer
                                                              }
                                                            />
                                                          </h4>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <h6
                                                              className="offerEndTime"
                                                              style={{
                                                                marginLeft:
                                                                  "9px",
                                                              }}
                                                            >
                                                              DAYS
                                                            </h6>
                                                            <h6
                                                              className="offerEndTime"
                                                              style={{
                                                                marginLeft:
                                                                  "13px",
                                                              }}
                                                            >
                                                              HOUR
                                                            </h6>
                                                            <h6
                                                              className="offerEndTime"
                                                              style={{
                                                                marginLeft:
                                                                  "12px",
                                                              }}
                                                            >
                                                              MIN
                                                            </h6>
                                                            <h6
                                                              className="offerEndTime"
                                                              style={{
                                                                marginLeft:
                                                                  "19px",
                                                              }}
                                                            >
                                                              SEC
                                                            </h6>
                                                          </div>
                                                        </IonBadge>
                                                      </div>
                                                    </IonCard>
                                                  )}

                                                <IonLabel
                                                  className="subscriptionDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      i?.description.english,
                                                  }}
                                                ></IonLabel>
                                              </div>
                                              <div>
                                                <div
                                                  className="btnFill"
                                                  style={{
                                                    margin:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <IonButton
                                                    type="submit"
                                                    expand="block"
                                                    onClick={() => {
                                                      setSelectSubscription(i);
                                                      payNow(i);
                                                    }}
                                                  >
                                                    Pay Now
                                                  </IonButton>
                                                </div>
                                              </div>

                                              {i?.payment_link ? (
                                                <div className=" ion-text-center">
                                                  <IonButton
                                                    slot="end"
                                                    type="button"
                                                    color={"primary"}
                                                    fill="clear"
                                                    shape="round"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      writeToClipboardPaymentLink(
                                                        i?.payment_link
                                                          ?.paymentLink
                                                      );
                                                      checkClipboard();
                                                    }}
                                                  >
                                                    {" "}
                                                    <IonIcon
                                                      icon={copyOutline}
                                                      color="primary"
                                                      style={{
                                                        verticalAlign: "sub",
                                                        fontSize: "18px",
                                                      }}
                                                      className="me-2"
                                                    />
                                                    <b>Copy Payment Link</b>
                                                  </IonButton>
                                                </div>
                                              ) : (
                                                <div className="mt-3  mb-2 ion-text-center">
                                                  <IonLabel
                                                    // color="primary"
                                                    style={{ color: "blue" }}
                                                    onClick={() => {
                                                      generateLink(i);
                                                    }}
                                                  >
                                                    <b>
                                                      Want To Generate Payment
                                                      Link ?
                                                    </b>
                                                  </IonLabel>
                                                </div>
                                              )}
                                            </IonCol>
                                          </IonRow>
                                        </IonCard>
                                      </div>
                                    </>
                                  );
                                })
                              : autoPackageDataBasic?.map((i: any) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          position: "relative",
                                          display: "flex",
                                          justifyContent: "center",
                                          top: "10px",
                                        }}
                                      >
                                        <div
                                          className="ms-3"
                                          style={{
                                            position: "absolute",
                                            top: "-3px",
                                            zIndex: "2",
                                          }}
                                        >
                                          {i?.package?.is_top_seller && (
                                            <IonBadge
                                              slot="end"
                                              color="success"
                                            >
                                              Top seller
                                            </IonBadge>
                                          )}
                                          {i?.package?.is_top_value && (
                                            <IonBadge
                                              slot="end"
                                              color="success"
                                            >
                                              Top value
                                            </IonBadge>
                                          )}
                                        </div>
                                        <IonCard
                                          className="subscriptionCardParent"
                                          onClick={() => {
                                            setShowMoreText(false);
                                            setSelectSubscription(i?.package);
                                          }}
                                          style={{
                                            border:
                                              SelectSubscription?.id ===
                                                i?.package?.id &&
                                              "1px solid #ee453d",
                                          }}
                                        >
                                          <IonRow>
                                            <IonCol size="12">
                                              <div className="offerCard">
                                                <IonItem className="subscriptionCard">
                                                  <IonAvatar className="icon-box">
                                                    <img
                                                      loading="lazy"
                                                      src={starImage}
                                                      style={{
                                                        minHeight: 2,
                                                        maxHeight: 50,
                                                        objectFit: "cover",
                                                        width: "92%",
                                                      }}
                                                    />
                                                  </IonAvatar>
                                                  <IonLabel
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      <b>
                                                        {
                                                          i?.package?.name
                                                            ?.english
                                                        }
                                                      </b>
                                                    </h3>
                                                    <p
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.package?.month > 59
                                                        ? "Till Marriage"
                                                        : i?.package?.month +
                                                          " months"}{" "}
                                                    </p>
                                                  </IonLabel>
                                                  {CurrentPackageBasic ===
                                                  null ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {AppContext.user
                                                          .no_offer_for_user ? (
                                                          <h3>
                                                            <b>
                                                              ₹{i?.package?.mrp}
                                                              .00{" "}
                                                            </b>
                                                            <br />
                                                            <b>0% off</b>
                                                          </h3>
                                                        ) : (
                                                          <h3>
                                                            {parseFloat(
                                                              (
                                                                ((i?.package
                                                                  ?.mrp -
                                                                  i?.ammount_one) /
                                                                  i?.mrp) *
                                                                100
                                                              ).toFixed(1)
                                                            )}
                                                            % OFF <br />
                                                            {i?.mrp !==
                                                              i?.ammount_one && (
                                                              <>
                                                                <s>
                                                                  ₹{i?.mrp}.00{" "}
                                                                </s>{" "}
                                                                <br />{" "}
                                                              </>
                                                            )}
                                                            <b
                                                              style={{
                                                                color:
                                                                  "#ffffff",
                                                              }}
                                                            >
                                                              ₹
                                                              {i?.mrp !==
                                                              i?.ammount_one
                                                                ? i?.ammount_one
                                                                : i?.mrp}
                                                              .00{" "}
                                                            </b>
                                                          </h3>
                                                        )}
                                                      </h3>
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english ===
                                                      "Platinum" &&
                                                    i?.package?.name
                                                      ?.english ===
                                                      "Platinum" ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {AppContext.user
                                                        .no_offer_for_user ? (
                                                        <h3>
                                                          <b>
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </b>
                                                          <br />
                                                          <b>0% off</b>
                                                        </h3>
                                                      ) : (
                                                        <h3>
                                                          <b
                                                            style={{
                                                              color: "#ffffff",
                                                            }}
                                                          >
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </b>
                                                        </h3>
                                                      )}
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english === "Gold" &&
                                                    i?.package?.name
                                                      ?.english ===
                                                      "Platinum" ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.package?.mrp -
                                                              i?.ammount_one) /
                                                              i?.package?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.package?.mrp !==
                                                          i?.ammount_one && (
                                                          <>
                                                            <s>
                                                              ₹{i?.package?.mrp}
                                                              .00{" "}
                                                            </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.package?.mrp !==
                                                          i?.ammount_one
                                                            ? i?.ammount_one
                                                            : i?.package?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    </IonLabel>
                                                  ) : CurrentPackageBasic?.name
                                                      ?.english === "Silver" &&
                                                    (i?.package?.name
                                                      ?.english ===
                                                      "Platinum" ||
                                                      i?.package?.name
                                                        ?.english ===
                                                        "Gold") ? (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.package?.mrp -
                                                              i?.ammount_one) /
                                                              i?.package?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.package?.mrp !==
                                                          i?.ammount_one && (
                                                          <>
                                                            <s>
                                                              ₹{i?.package?.mrp}
                                                              .00{" "}
                                                            </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.package?.mrp !==
                                                          i?.ammount_one
                                                            ? i?.ammount_one
                                                            : i?.package?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    </IonLabel>
                                                  ) : (
                                                    <IonLabel
                                                      slot="end"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    >
                                                      {AppContext.user
                                                        .no_offer_for_user ? (
                                                        <h3>
                                                          <b>
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </b>
                                                          <br />
                                                          <b>0% off</b>
                                                        </h3>
                                                      ) : (
                                                        <h3>
                                                          <b
                                                            style={{
                                                              color: "#ffffff",
                                                            }}
                                                          >
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </b>
                                                        </h3>
                                                      )}
                                                    </IonLabel>
                                                  )}
                                                </IonItem>
                                              </div>

                                              <div>
                                                {new Date(
                                                  i?.package?.offerExpireDate
                                                ).getTime() > 0 && (
                                                  <IonCard className="offerEnd px-3">
                                                    <div>
                                                      <IonLabel>
                                                        <h1>OFFER</h1>
                                                        <IonLabel></IonLabel>
                                                        <h2
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          ENDS IN
                                                        </h2>
                                                      </IonLabel>
                                                    </div>
                                                    <div>
                                                      <IonBadge
                                                        style={{
                                                          backgroundColor:
                                                            "#fcf6f6",
                                                        }}
                                                      >
                                                        <h4
                                                          style={{
                                                            marginBottom: "0",
                                                            fontWeight: "600",
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          <Countdown
                                                            date={
                                                              new Date(
                                                                new Date(
                                                                  i?.offerExpireDate
                                                                ).getTime() -
                                                                  (5 * 60 +
                                                                    30) *
                                                                    60 *
                                                                    1000
                                                              )
                                                            }
                                                            renderer={renderer}
                                                          />
                                                        </h4>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft: "9px",
                                                            }}
                                                          >
                                                            DAYS
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          >
                                                            HOUR
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "12px",
                                                            }}
                                                          >
                                                            MIN
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "19px",
                                                            }}
                                                          >
                                                            SEC
                                                          </h6>
                                                        </div>
                                                      </IonBadge>
                                                    </div>
                                                  </IonCard>
                                                )}

                                                <IonLabel
                                                  className="subscriptionDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      i?.package?.description
                                                        .english,
                                                  }}
                                                ></IonLabel>
                                              </div>
                                              <div>
                                                <div
                                                  className="btnFill"
                                                  style={{
                                                    margin:
                                                      "10px 10px 10px 10px",
                                                  }}
                                                >
                                                  <IonButton
                                                    type="submit"
                                                    expand="block"
                                                    onClick={() => {
                                                      setSelectSubscription(
                                                        i?.package
                                                      );
                                                      payNow(i?.package);
                                                    }}
                                                  >
                                                    Pay Now
                                                  </IonButton>
                                                </div>
                                              </div>

                                              {i?.package?.payment_link ? (
                                                <div className=" ion-text-center">
                                                  <IonButton
                                                    slot="end"
                                                    type="button"
                                                    color={"primary"}
                                                    fill="clear"
                                                    shape="round"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      writeToClipboardPaymentLink(
                                                        i?.package?.payment_link
                                                          ?.paymentLink
                                                      );
                                                      checkClipboard();
                                                    }}
                                                  >
                                                    {" "}
                                                    <IonIcon
                                                      icon={copyOutline}
                                                      color="primary"
                                                      style={{
                                                        verticalAlign: "sub",
                                                        fontSize: "18px",
                                                      }}
                                                      className="me-2"
                                                    />
                                                    <b>Copy Payment Link</b>
                                                  </IonButton>
                                                </div>
                                              ) : (
                                                <div className="mt-3  mb-2 ion-text-center">
                                                  <IonLabel
                                                    // color="primary"
                                                    style={{ color: "blue" }}
                                                    onClick={() => {
                                                      generateLink(i?.package);
                                                    }}
                                                  >
                                                    <b>
                                                      Want To Generate Payment
                                                      Link ?
                                                    </b>
                                                  </IonLabel>
                                                </div>
                                              )}
                                            </IonCol>
                                          </IonRow>
                                        </IonCard>
                                      </div>
                                    </>
                                  );
                                })}
                          </div>
                        )
                      : section === "basic" && (
                          <div>
                            {autoPackageDataBasic?.length === 0
                              ? Subscription?.map((i: any) => {
                                  const expirationDate: any = new Date(
                                    i?.offerExpireDate
                                  ).getTime();
                                  const isExpired =
                                    expirationDate <= new Date().getTime();
                                  return (
                                    <div
                                      key={i?.id}
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        top: "10px",
                                      }}
                                    >
                                      <div
                                        className="ms-3"
                                        style={{
                                          position: "absolute",
                                          top: "-3px",
                                          zIndex: "2",
                                        }}
                                      >
                                        {i?.is_top_seller && (
                                          <IonBadge slot="end" color="success">
                                            Top seller
                                          </IonBadge>
                                        )}
                                        {i?.is_top_value && (
                                          <IonBadge slot="end" color="success">
                                            Top value
                                          </IonBadge>
                                        )}
                                      </div>
                                      <IonCard
                                        className="subscriptionCardParent"
                                        onClick={() => {
                                          setShowMoreText(false);
                                          setSelectSubscription(i);
                                        }}
                                        style={{
                                          border:
                                            SelectSubscription?.id === i?.id &&
                                            "1px solid #ee453d",
                                        }}
                                      >
                                        <IonRow>
                                          <IonCol size="12">
                                            <div className="offerCard">
                                              <IonItem className="subscriptionCard">
                                                <IonAvatar className="icon-box">
                                                  <img
                                                    loading="lazy"
                                                    src={starImage}
                                                    style={{
                                                      minHeight: 2,
                                                      maxHeight: 50,
                                                      objectFit: "cover",
                                                      width: "92%",
                                                    }}
                                                  />
                                                </IonAvatar>
                                                <IonLabel
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  <h3>
                                                    <b>{i?.name?.english}</b>
                                                  </h3>
                                                  <p
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {" "}
                                                    {i?.month > 59
                                                      ? "Till Marriage"
                                                      : i?.month +
                                                        " months"}{" "}
                                                  </p>
                                                </IonLabel>
                                                {CurrentPackageBasic ===
                                                null ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>₹{i?.mrp}.00 </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.mrp -
                                                              i?.price) /
                                                              i?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.mrp !==
                                                          i?.price && (
                                                          <>
                                                            <s>₹{i?.mrp}.00 </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.mrp !== i?.price
                                                            ? i?.price
                                                            : i?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Platinum" &&
                                                  i?.name?.english ===
                                                    "Platinum" ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>₹{i?.mrp}.00 </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹{i?.mrp}.00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Gold" &&
                                                  i?.name?.english ===
                                                    "Platinum" ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      {parseFloat(
                                                        (
                                                          ((i?.mrp - i?.price) /
                                                            i?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      )}
                                                      % OFF <br />
                                                      {i?.mrp !== i?.price && (
                                                        <>
                                                          <s>₹{i?.mrp}.00 </s>{" "}
                                                          <br />{" "}
                                                        </>
                                                      )}
                                                      <b
                                                        style={{
                                                          color: "#ffffff",
                                                        }}
                                                      >
                                                        ₹
                                                        {i?.mrp !== i?.price
                                                          ? i?.price
                                                          : i?.mrp}
                                                        .00{" "}
                                                      </b>
                                                    </h3>
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Silver" &&
                                                  (i?.name?.english ===
                                                    "Platinum" ||
                                                    i?.name?.english ===
                                                      "Gold") ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      {parseFloat(
                                                        (
                                                          ((i?.mrp - i?.price) /
                                                            i?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      )}
                                                      % OFF <br />
                                                      {i?.mrp !== i?.price && (
                                                        <>
                                                          <s>₹{i?.mrp}.00 </s>{" "}
                                                          <br />{" "}
                                                        </>
                                                      )}
                                                      <b
                                                        style={{
                                                          color: "#ffffff",
                                                        }}
                                                      >
                                                        ₹
                                                        {i?.mrp !== i?.price
                                                          ? i?.price
                                                          : i?.mrp}
                                                        .00{" "}
                                                      </b>
                                                    </h3>
                                                  </IonLabel>
                                                ) : (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>₹{i?.mrp}.00 </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹{i?.mrp}.00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                )}
                                              </IonItem>
                                            </div>

                                            <div>
                                              {!isExpired &&
                                                new Date(
                                                  i?.offerExpireDate
                                                ).getTime() > 0 && (
                                                  <IonCard className="offerEnd px-3">
                                                    <div>
                                                      <IonLabel>
                                                        <h1>OFFER</h1>
                                                        <IonLabel></IonLabel>
                                                        <h2
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          ENDS IN
                                                        </h2>
                                                      </IonLabel>
                                                    </div>
                                                    <div>
                                                      <IonBadge
                                                        style={{
                                                          backgroundColor:
                                                            "#fcf6f6",
                                                        }}
                                                      >
                                                        <h4
                                                          style={{
                                                            marginBottom: "0",
                                                            fontWeight: "600",
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          <Countdown
                                                            date={
                                                              new Date(
                                                                new Date(
                                                                  i?.offerExpireDate
                                                                ).getTime()
                                                              )
                                                            }
                                                            renderer={renderer}
                                                          />
                                                        </h4>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft: "9px",
                                                            }}
                                                          >
                                                            DAYS
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "13px",
                                                            }}
                                                          >
                                                            HOUR
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "12px",
                                                            }}
                                                          >
                                                            MIN
                                                          </h6>
                                                          <h6
                                                            className="offerEndTime"
                                                            style={{
                                                              marginLeft:
                                                                "19px",
                                                            }}
                                                          >
                                                            SEC
                                                          </h6>
                                                        </div>
                                                      </IonBadge>
                                                    </div>
                                                  </IonCard>
                                                )}

                                              <IonLabel
                                                className="subscriptionDesc"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    i?.description.english,
                                                }}
                                              ></IonLabel>
                                            </div>
                                            <div>
                                              <div
                                                className="btnFill"
                                                style={{
                                                  margin: "10px 10px 10px 10px",
                                                }}
                                              >
                                                <IonButton
                                                  type="submit"
                                                  expand="block"
                                                  onClick={() => {
                                                    setSelectSubscription(i);
                                                    payNow(i);
                                                  }}
                                                >
                                                  Pay Now
                                                </IonButton>
                                              </div>
                                            </div>

                                            {i?.payment_link ? (
                                              <div className=" ion-text-center">
                                                <IonButton
                                                  slot="end"
                                                  type="button"
                                                  color={"primary"}
                                                  fill="clear"
                                                  shape="round"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    writeToClipboardPaymentLink(
                                                      i?.payment_link
                                                        ?.paymentLink
                                                    );
                                                    checkClipboard();
                                                  }}
                                                >
                                                  {" "}
                                                  <IonIcon
                                                    icon={copyOutline}
                                                    color="primary"
                                                    style={{
                                                      verticalAlign: "sub",
                                                      fontSize: "18px",
                                                    }}
                                                    className="me-2"
                                                  />
                                                  <b>Copy Payment Link</b>
                                                </IonButton>
                                              </div>
                                            ) : (
                                              <div className="mt-3  mb-2 ion-text-center">
                                                <IonLabel
                                                  // color="primary"
                                                  style={{ color: "blue" }}
                                                  onClick={() => {
                                                    generateLink(i);
                                                  }}
                                                >
                                                  <b>
                                                    Want To Generate Payment
                                                    Link ?
                                                  </b>
                                                </IonLabel>
                                              </div>
                                            )}
                                          </IonCol>
                                        </IonRow>
                                      </IonCard>
                                    </div>
                                  );
                                })
                              : autoPackageDataBasic?.map((i: any) => {
                                  return (
                                    <div
                                      key={i?.id}
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        top: "10px",
                                      }}
                                    >
                                      <div
                                        className="ms-3"
                                        style={{
                                          position: "absolute",
                                          top: "-3px",
                                          zIndex: "2",
                                        }}
                                      >
                                        {i?.package?.is_top_seller && (
                                          <IonBadge slot="end" color="success">
                                            Top seller
                                          </IonBadge>
                                        )}
                                        {i?.package?.is_top_value && (
                                          <IonBadge slot="end" color="success">
                                            Top value
                                          </IonBadge>
                                        )}
                                      </div>
                                      <IonCard
                                        className="subscriptionCardParent"
                                        onClick={() => {
                                          setShowMoreText(false);
                                          setSelectSubscription(i?.package);
                                        }}
                                        style={{
                                          border:
                                            SelectSubscription?.id ===
                                              i?.package?.id &&
                                            "1px solid #ee453d",
                                        }}
                                      >
                                        <IonRow>
                                          <IonCol size="12">
                                            <div className="offerCard">
                                              <IonItem className="subscriptionCard">
                                                <IonAvatar className="icon-box">
                                                  <img
                                                    loading="lazy"
                                                    src={starImage}
                                                    style={{
                                                      minHeight: 2,
                                                      maxHeight: 50,
                                                      objectFit: "cover",
                                                      width: "92%",
                                                    }}
                                                  />
                                                </IonAvatar>
                                                <IonLabel
                                                  style={{ color: "#ffffff" }}
                                                >
                                                  <h3>
                                                    <b>
                                                      {
                                                        i?.package?.name
                                                          ?.english
                                                      }
                                                    </b>
                                                  </h3>
                                                  <p
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {" "}
                                                    {i?.package?.month > 59
                                                      ? "Till Marriage"
                                                      : i?.package?.month +
                                                        " months"}{" "}
                                                  </p>
                                                </IonLabel>
                                                {CurrentPackageBasic ===
                                                null ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>
                                                          ₹{i?.package?.mrp}.00{" "}
                                                        </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        {parseFloat(
                                                          (
                                                            ((i?.package?.mrp -
                                                              i?.ammount_one) /
                                                              i?.package?.mrp) *
                                                            100
                                                          ).toFixed(1)
                                                        )}
                                                        % OFF <br />
                                                        {i?.package?.mrp !==
                                                          i?.ammount_one && (
                                                          <>
                                                            <s>
                                                              ₹{i?.package?.mrp}
                                                              .00{" "}
                                                            </s>{" "}
                                                            <br />{" "}
                                                          </>
                                                        )}
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹
                                                          {i?.package?.mrp !==
                                                          i?.ammount_one
                                                            ? i?.ammount_one
                                                            : i?.package?.mrp}
                                                          .00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Platinum" &&
                                                  i?.package?.name?.english ===
                                                    "Platinum" ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>
                                                          ₹{i?.package?.mrp}.00{" "}
                                                        </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹{i?.package?.mrp}.00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Gold" &&
                                                  i?.package?.name?.english ===
                                                    "Platinum" ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      {parseFloat(
                                                        (
                                                          ((i?.package?.mrp -
                                                            i?.ammount_one) /
                                                            i?.package?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      )}
                                                      % OFF <br />
                                                      {i?.package?.mrp !==
                                                        i?.ammount_one && (
                                                        <>
                                                          <s>
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </s>{" "}
                                                          <br />{" "}
                                                        </>
                                                      )}
                                                      <b
                                                        style={{
                                                          color: "#ffffff",
                                                        }}
                                                      >
                                                        ₹
                                                        {i?.package?.mrp !==
                                                        i?.ammount_one
                                                          ? i?.ammount_one
                                                          : i?.package?.mrp}
                                                        .00{" "}
                                                      </b>
                                                    </h3>
                                                  </IonLabel>
                                                ) : CurrentPackageBasic?.pacakge
                                                    ?.name?.english ===
                                                    "Silver" &&
                                                  (i?.package?.name?.english ===
                                                    "Platinum" ||
                                                    i?.package?.name
                                                      ?.english === "Gold") ? (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    <h3>
                                                      {parseFloat(
                                                        (
                                                          ((i?.package?.mrp -
                                                            i?.ammount_one) /
                                                            i?.package?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      )}
                                                      % OFF <br />
                                                      {i?.package?.mrp !==
                                                        i?.ammount_one && (
                                                        <>
                                                          <s>
                                                            ₹{i?.package?.mrp}
                                                            .00{" "}
                                                          </s>{" "}
                                                          <br />{" "}
                                                        </>
                                                      )}
                                                      <b
                                                        style={{
                                                          color: "#ffffff",
                                                        }}
                                                      >
                                                        ₹
                                                        {i?.package?.mrp !==
                                                        i?.ammount_one
                                                          ? i?.ammount_one
                                                          : i?.package?.mrp}
                                                        .00{" "}
                                                      </b>
                                                    </h3>
                                                  </IonLabel>
                                                ) : (
                                                  <IonLabel
                                                    slot="end"
                                                    style={{ color: "#ffffff" }}
                                                  >
                                                    {AppContext.user
                                                      .no_offer_for_user ? (
                                                      <h3>
                                                        <b>
                                                          ₹{i?.package?.mrp}.00{" "}
                                                        </b>
                                                        <br />
                                                        <b>0% off</b>
                                                      </h3>
                                                    ) : (
                                                      <h3>
                                                        <b
                                                          style={{
                                                            color: "#ffffff",
                                                          }}
                                                        >
                                                          ₹{i?.package?.mrp}.00{" "}
                                                        </b>
                                                      </h3>
                                                    )}
                                                  </IonLabel>
                                                )}
                                              </IonItem>
                                            </div>

                                            <div>
                                              {new Date(
                                                i?.package?.offerExpireDate
                                              ).getTime() > 0 && (
                                                <IonCard className="offerEnd px-3">
                                                  <div>
                                                    <IonLabel>
                                                      <h1>OFFER</h1>
                                                      <IonLabel></IonLabel>
                                                      <h2
                                                        style={{
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        ENDS IN
                                                      </h2>
                                                    </IonLabel>
                                                  </div>
                                                  <div>
                                                    <IonBadge
                                                      style={{
                                                        backgroundColor:
                                                          "#fcf6f6",
                                                      }}
                                                    >
                                                      <h4
                                                        style={{
                                                          marginBottom: "0",
                                                          fontWeight: "600",
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        <Countdown
                                                          date={
                                                            new Date(
                                                              new Date(
                                                                i?.package?.offerExpireDate
                                                              ).getTime() -
                                                                (5 * 60 + 30) *
                                                                  60 *
                                                                  1000
                                                            )
                                                          }
                                                          renderer={renderer}
                                                        />
                                                      </h4>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <h6
                                                          className="offerEndTime"
                                                          style={{
                                                            marginLeft: "9px",
                                                          }}
                                                        >
                                                          DAYS
                                                        </h6>
                                                        <h6
                                                          className="offerEndTime"
                                                          style={{
                                                            marginLeft: "13px",
                                                          }}
                                                        >
                                                          HOUR
                                                        </h6>
                                                        <h6
                                                          className="offerEndTime"
                                                          style={{
                                                            marginLeft: "12px",
                                                          }}
                                                        >
                                                          MIN
                                                        </h6>
                                                        <h6
                                                          className="offerEndTime"
                                                          style={{
                                                            marginLeft: "19px",
                                                          }}
                                                        >
                                                          SEC
                                                        </h6>
                                                      </div>
                                                    </IonBadge>
                                                  </div>
                                                </IonCard>
                                              )}

                                              <IonLabel
                                                className="subscriptionDesc"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    i?.package?.description
                                                      .english,
                                                }}
                                              ></IonLabel>
                                            </div>
                                            <div>
                                              <div
                                                className="btnFill"
                                                style={{
                                                  margin: "10px 10px 10px 10px",
                                                }}
                                              >
                                                <IonButton
                                                  type="submit"
                                                  expand="block"
                                                  onClick={() => {
                                                    setSelectSubscription(
                                                      i?.package
                                                    );
                                                    payNow(i?.package);
                                                  }}
                                                >
                                                  Pay Now
                                                </IonButton>
                                              </div>
                                            </div>

                                            {i?.package?.payment_link ? (
                                              <div className=" ion-text-center">
                                                <IonButton
                                                  slot="end"
                                                  type="button"
                                                  color={"primary"}
                                                  fill="clear"
                                                  shape="round"
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    writeToClipboardPaymentLink(
                                                      i?.package?.payment_link
                                                        ?.paymentLink
                                                    );
                                                    checkClipboard();
                                                  }}
                                                >
                                                  {" "}
                                                  <IonIcon
                                                    icon={copyOutline}
                                                    color="primary"
                                                    style={{
                                                      verticalAlign: "sub",
                                                      fontSize: "18px",
                                                    }}
                                                    className="me-2"
                                                  />
                                                  <b>Copy Payment Link</b>
                                                </IonButton>
                                              </div>
                                            ) : (
                                              <div className="mt-3  mb-2 ion-text-center">
                                                <IonLabel
                                                  // color="primary"
                                                  style={{ color: "blue" }}
                                                  onClick={() => {
                                                    generateLink(i?.package);
                                                  }}
                                                >
                                                  <b>
                                                    Want To Generate Payment
                                                    Link ?
                                                  </b>
                                                </IonLabel>
                                              </div>
                                            )}
                                          </IonCol>
                                        </IonRow>
                                      </IonCard>
                                    </div>
                                  );
                                })}
                          </div>
                        )}

                    {/* special card display */}
                    {CurrentPackageSpecial !== null
                      ? section === "special" && (
                          <div>
                            {
                              <>
                                {autoPackageDataSpecial?.length === 0
                                  ? specialList?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {i?.name?.english}
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {CurrentPackageSpecial &&
                                                  CurrentPackageSpecial?.name?.english.trim() ===
                                                    i?.name?.english.trim()
                                                    ? 1 && (
                                                        <>
                                                          <div className="discountCardcontainer">
                                                            <IonCard
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {CurrentPackageSpecial?.package_month >
                                                                  59
                                                                    ? "Till Marriage"
                                                                    : `for ${CurrentPackageSpecial?.package_month} months`}
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                <h1
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    CurrentPackageSpecial?.amount
                                                                  }
                                                                </h1>
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  Current Plan
                                                                </h2>
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </div>
                                                        </>
                                                      )
                                                    : null}
                                                  <div>
                                                    {new Date(
                                                      i?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageSpecial &&
                                                  CurrentPackageSpecial?.name?.english.trim() ===
                                                    i?.name?.english.trim()
                                                    ? upgradeSpecial
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.mrp - i?.price) /
                                                            i?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.mrp -
                                                                    i?.price) /
                                                                    i?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.mrp && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month}{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹{i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrp !==
                                                                      i?.price && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrp !==
                                                                      i?.price
                                                                        ? i?.price
                                                                        : i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrp -
                                                                          i?.price) /
                                                                          i?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrp -
                                                                              i?.price) /
                                                                              i?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrpone && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_one}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrpone !==
                                                                      i?.priceone && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrpone !==
                                                                      i?.priceone
                                                                        ? i?.priceone
                                                                        : i?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrpone -
                                                                          i?.priceone) /
                                                                          i?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrpone -
                                                                              i?.priceone) /
                                                                              i?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrptwo && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_two}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrptwo !==
                                                                      i?.pricetwo && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrptwo !==
                                                                      i?.pricetwo
                                                                        ? i?.pricetwo
                                                                        : i?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrptwo -
                                                                          i?.pricetwo) /
                                                                          i?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrptwo -
                                                                              i?.pricetwo) /
                                                                              i?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}

                                                  <div className="my-2">
                                                    {CurrentPackageSpecial ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.price
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrpone
                                                                  : i?.priceone
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.pricetwo
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? i?.month
                                                                : scaledCardTwo
                                                                ? i?.month_one
                                                                : scaledCardThree
                                                                ? i?.month_two
                                                                : null;
                                                            handleSwitchClick();
                                                            setPkgNameSpecial({
                                                              pkgMonth:
                                                                pkgMonth,
                                                              price: price,
                                                              i: i,
                                                            });
                                                          }}
                                                        >
                                                          Pay Now
                                                        </IonButton>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              CurrentPackageSpecial &&
                                                              CurrentPackageSpecial?.name?.english.trim() ===
                                                                "Mediator Service" &&
                                                              CurrentPackageSpecial?.name?.english.trim() ===
                                                                i?.name?.english.trim() &&
                                                              (CurrentPackageSpecial?.amount >
                                                                i?.price ||
                                                                CurrentPackageSpecial?.amount >
                                                                  i?.priceone ||
                                                                CurrentPackageSpecial?.amount >
                                                                  i?.pricetwo)
                                                            ) {
                                                              setSection("VIP");
                                                              setSelectState({
                                                                ...selectState,
                                                                status: "vip",
                                                              });
                                                            } else {
                                                              if (
                                                                CurrentPackageSpecial?.name?.english.trim() ===
                                                                i?.name?.english.trim()
                                                              ) {
                                                                if (
                                                                  CurrentPackageSpecial &&
                                                                  section ===
                                                                    "special" &&
                                                                  upgradeSpecial ===
                                                                    false
                                                                ) {
                                                                  setUpgradeSpecial(
                                                                    true
                                                                  );
                                                                } else {
                                                                  let price =
                                                                    scaledCardOne
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i?.mrptwo
                                                                        : i?.price
                                                                      : scaledCardTwo
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i?.mrpone
                                                                        : i?.priceone
                                                                      : scaledCardThree
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i?.mrptwo
                                                                        : i?.pricetwo
                                                                      : null;
                                                                  let pkgMonth =
                                                                    scaledCardOne
                                                                      ? i?.month
                                                                      : scaledCardTwo
                                                                      ? i?.month_one
                                                                      : scaledCardThree
                                                                      ? i?.month_two
                                                                      : null;
                                                                  setSelectSubscription(
                                                                    i
                                                                  );
                                                                  payNowSpecialVip(
                                                                    price,
                                                                    i,
                                                                    pkgMonth
                                                                  );
                                                                }
                                                              } else {
                                                                let price =
                                                                  scaledCardOne
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i?.mrptwo
                                                                      : i?.price
                                                                    : scaledCardTwo
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i?.mrpone
                                                                      : i?.priceone
                                                                    : scaledCardThree
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i?.mrptwo
                                                                      : i?.pricetwo
                                                                    : null;
                                                                let pkgMonth =
                                                                  scaledCardOne
                                                                    ? i?.month
                                                                    : scaledCardTwo
                                                                    ? i?.month_one
                                                                    : scaledCardThree
                                                                    ? i?.month_two
                                                                    : null;
                                                                if (
                                                                  price &&
                                                                  CurrentPackageBasic !==
                                                                    null
                                                                ) {
                                                                  setSelectSubscription(
                                                                    i
                                                                  );
                                                                  payNowSpecialVip(
                                                                    price,
                                                                    i,
                                                                    pkgMonth
                                                                  );
                                                                } else if (
                                                                  price &&
                                                                  CurrentPackageBasic ===
                                                                    null
                                                                ) {
                                                                  handleSwitchClick();
                                                                  setPkgNameSpecial(
                                                                    {
                                                                      pkgMonth:
                                                                        pkgMonth,
                                                                      price:
                                                                        price,
                                                                      i: i,
                                                                    }
                                                                  );
                                                                } else {
                                                                  setError(
                                                                    true
                                                                  );
                                                                }
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          {CurrentPackageSpecial &&
                                                          section ===
                                                            "special" &&
                                                          upgradeSpecial ===
                                                            false &&
                                                          CurrentPackageSpecial?.name?.english.trim() ===
                                                            i?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                        {error && (
                                                          <p className="fs-16 text-danger p-1">
                                                            Please select any
                                                            one Package
                                                          </p>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })
                                  : autoPackageDataSpecial?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                i?.package?.name
                                                                  ?.english
                                                              }
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.package?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.package
                                                            ?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {CurrentPackageSpecial &&
                                                  CurrentPackageSpecial?.name?.english.trim() ===
                                                    i?.package?.name?.english.trim()
                                                    ? 1 && (
                                                        <>
                                                          <div className="discountCardcontainer">
                                                            <IonCard
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {CurrentPackageSpecial?.package_month >
                                                                  59
                                                                    ? "Till Marriage"
                                                                    : `for ${CurrentPackageSpecial?.package_month} months`}
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                <h1
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    CurrentPackageSpecial?.amount
                                                                  }
                                                                </h1>
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  Current Plan
                                                                </h2>
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </div>
                                                        </>
                                                      )
                                                    : null}
                                                  <div>
                                                    {new Date(
                                                      i?.package?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageSpecial &&
                                                  CurrentPackageSpecial?.name?.english.trim() ===
                                                    i?.package?.name?.english.trim()
                                                    ? upgradeSpecial
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.package?.mrp -
                                                            i?.ammount_one) /
                                                            i?.package?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.package
                                                                    ?.mrp -
                                                                    i?.ammount_one) /
                                                                    i?.package
                                                                      ?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.package?.mrp && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month
                                                                  }{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrp
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrp !==
                                                                      i?.ammount_one && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrp !==
                                                                      i?.ammount_one
                                                                        ? i?.ammount_one
                                                                        : i
                                                                            ?.package
                                                                            ?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrp -
                                                                          i?.ammount_one) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrp -
                                                                              i?.ammount_one) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrpone && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_one
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrpone !==
                                                                      i?.ammount_two && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrpone !==
                                                                      i?.ammount_two
                                                                        ? i?.ammount_two
                                                                        : i
                                                                            ?.package
                                                                            ?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrpone -
                                                                          i?.ammount_two) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrpone -
                                                                              i?.ammount_two) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrptwo && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_two
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrptwo !==
                                                                      i?.ammount_three && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrptwo !==
                                                                      i?.ammount_three
                                                                        ? i?.ammount_three
                                                                        : i
                                                                            ?.package
                                                                            ?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrptwo -
                                                                          i?.ammount_three) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrptwo -
                                                                              i?.ammount_three) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}

                                                  <div className="height-md-100">
                                                    {CurrentPackageSpecial ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_one
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrpone
                                                                  : i?.ammount_two
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_three
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? i?.package
                                                                    ?.month
                                                                : scaledCardTwo
                                                                ? i?.package
                                                                    ?.month_one
                                                                : scaledCardThree
                                                                ? i?.package
                                                                    ?.month_two
                                                                : null;
                                                            let pkg =
                                                              i?.package;
                                                            handleSwitchClick();
                                                            setPkgNameSpecial({
                                                              pkgMonth:
                                                                pkgMonth,
                                                              price: price,
                                                              i: pkg,
                                                            });
                                                          }}
                                                        >
                                                          Pay Now
                                                        </IonButton>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              CurrentPackageSpecial &&
                                                              CurrentPackageSpecial?.name?.english.trim() ===
                                                                "Mediator Service" &&
                                                              CurrentPackageSpecial?.name?.english.trim() ===
                                                                i?.package?.name?.english.trim() &&
                                                              (CurrentPackageSpecial?.amount >
                                                                i?.ammount_one ||
                                                                CurrentPackageSpecial?.amount >
                                                                  i?.ammount_two ||
                                                                CurrentPackageSpecial?.amount >
                                                                  i?.ammount_three)
                                                            ) {
                                                              setSection("VIP");
                                                              setSelectState({
                                                                ...selectState,
                                                                status: "vip",
                                                              });
                                                            } else {
                                                              if (
                                                                CurrentPackageSpecial?.name?.english.trim() ===
                                                                i?.package?.name?.english.trim()
                                                              ) {
                                                                if (
                                                                  CurrentPackageSpecial &&
                                                                  section ===
                                                                    "special" &&
                                                                  upgradeSpecial ===
                                                                    false
                                                                ) {
                                                                  setUpgradeSpecial(
                                                                    true
                                                                  );
                                                                } else {
                                                                  let pkgMonth =
                                                                    scaledCardOne
                                                                      ? i
                                                                          ?.package
                                                                          ?.month
                                                                      : scaledCardTwo
                                                                      ? i
                                                                          ?.package
                                                                          ?.month_one
                                                                      : scaledCardThree
                                                                      ? i
                                                                          ?.package
                                                                          ?.month_two
                                                                      : null;
                                                                  let price =
                                                                    scaledCardOne
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i
                                                                            ?.package
                                                                            ?.mrptwo
                                                                        : i?.ammount_one
                                                                      : scaledCardTwo
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i
                                                                            ?.package
                                                                            ?.mrpone
                                                                        : i?.ammount_two
                                                                      : scaledCardThree
                                                                      ? AppContext
                                                                          .user
                                                                          .no_offer_for_user
                                                                        ? i
                                                                            ?.package
                                                                            ?.mrptwo
                                                                        : i?.ammount_three
                                                                      : null;
                                                                  setSelectSubscription(
                                                                    i?.package
                                                                  );
                                                                  payNowSpecialVip(
                                                                    price,
                                                                    i?.package,
                                                                    pkgMonth
                                                                  );
                                                                }
                                                              } else {
                                                                let price =
                                                                  scaledCardOne
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      : i?.ammount_one
                                                                    : scaledCardTwo
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i
                                                                          ?.package
                                                                          ?.mrpone
                                                                      : i?.ammount_two
                                                                    : scaledCardThree
                                                                    ? AppContext
                                                                        .user
                                                                        .no_offer_for_user
                                                                      ? i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      : i?.ammount_three
                                                                    : null;
                                                                let pkgMonth =
                                                                  scaledCardOne
                                                                    ? i?.package
                                                                        ?.month
                                                                    : scaledCardTwo
                                                                    ? i?.package
                                                                        ?.month_one
                                                                    : scaledCardThree
                                                                    ? i?.package
                                                                        ?.month_two
                                                                    : null;
                                                                if (
                                                                  price &&
                                                                  CurrentPackageBasic !==
                                                                    null
                                                                ) {
                                                                  setSelectSubscription(
                                                                    i?.package
                                                                  );
                                                                  payNowSpecialVip(
                                                                    price,
                                                                    i?.package,
                                                                    pkgMonth
                                                                  );
                                                                } else if (
                                                                  price &&
                                                                  CurrentPackageBasic ===
                                                                    null
                                                                ) {
                                                                  let pkg =
                                                                    i?.package;
                                                                  handleSwitchClick();
                                                                  setPkgNameSpecial(
                                                                    {
                                                                      pkgMonth:
                                                                        pkgMonth,
                                                                      price:
                                                                        price,
                                                                      i: pkg,
                                                                    }
                                                                  );
                                                                } else {
                                                                  setError(
                                                                    true
                                                                  );
                                                                }
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          {CurrentPackageSpecial &&
                                                          section ===
                                                            "special" &&
                                                          upgradeSpecial ===
                                                            false &&
                                                          CurrentPackageSpecial?.name?.english.trim() ===
                                                            i?.package?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                        {error && (
                                                          <p className="fs-16 text-danger p-1">
                                                            Please select any
                                                            one Package
                                                          </p>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })}
                                <IonFooter
                                  style={{
                                    display: "grid",
                                    position: "sticky",
                                    bottom: "0",
                                    backgroundColor: "#ef423b",
                                  }}
                                >
                                  <IonCard
                                    className="bg-none mb-1 mt-1 ion-text-center"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        color: "white",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Need Help ?
                                    </p>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onWhatsAppMessage();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      WhatsApp{" "}
                                    </IonButton>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onJsmCall();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      Call Us{" "}
                                    </IonButton>
                                  </IonCard>
                                </IonFooter>
                              </>
                            }
                          </div>
                        )
                      : section === "special" && (
                          <div>
                            {
                              <>
                                {autoPackageDataSpecial?.length === 0
                                  ? specialList?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {i?.name?.english}
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  <div>
                                                    {new Date(
                                                      i?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageSpecial?.name?.english.trim() ===
                                                  i?.name?.english.trim()
                                                    ? upgradeSpecial
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.mrp - i?.price) /
                                                            i?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.mrp -
                                                                    i?.price) /
                                                                    i?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.mrp && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month}{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹{i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.price !==
                                                                      i?.mrp && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrp !==
                                                                      i?.price
                                                                        ? i?.price
                                                                        : i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrp -
                                                                          i?.price) /
                                                                          i?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrp -
                                                                              i?.price) /
                                                                              i?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrpone && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_one}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrpone !==
                                                                      i?.priceone && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrpone !==
                                                                      i?.priceone
                                                                        ? i?.priceone
                                                                        : i?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrpone -
                                                                          i?.priceone) /
                                                                          i?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrpone -
                                                                              i?.priceone) /
                                                                              i?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrptwo && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_two}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrptwo !==
                                                                      i?.pricetwo && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrptwo !==
                                                                      i?.pricetwo
                                                                        ? i?.pricetwo
                                                                        : i?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrptwo -
                                                                          i?.pricetwo) /
                                                                          i?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrptwo -
                                                                              i?.pricetwo) /
                                                                              i?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}
                                                  <div>
                                                    {CurrentPackageSpecial ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.price
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrpone
                                                                  : i?.priceone
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.pricetwo
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? i?.month
                                                                : scaledCardTwo
                                                                ? i?.month_one
                                                                : scaledCardThree
                                                                ? i?.month_two
                                                                : null;
                                                            if (pkgMonth) {
                                                              setError(false);
                                                              handleSwitchClick();
                                                              setPkgNameSpecial(
                                                                {
                                                                  pkgMonth:
                                                                    pkgMonth,
                                                                  price: price,
                                                                  i: i,
                                                                }
                                                              );
                                                            } else {
                                                              setError(true);
                                                            }
                                                          }}
                                                        >
                                                          {section === "special"
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                        {error && (
                                                          <p className="fs-16 text-danger p-1">
                                                            Please select any
                                                            one Package
                                                          </p>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              section ===
                                                                "special" &&
                                                              upgradeSpecial ===
                                                                false
                                                            ) {
                                                              setUpgradeSpecial(
                                                                true
                                                              );
                                                            } else {
                                                              let price =
                                                                scaledCardOne
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrptwo
                                                                    : i?.price
                                                                  : scaledCardTwo
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrpone
                                                                    : i?.priceone
                                                                  : scaledCardThree
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrptwo
                                                                    : i?.pricetwo
                                                                  : null;
                                                              let pkgMonth =
                                                                scaledCardOne
                                                                  ? i?.month
                                                                  : scaledCardTwo
                                                                  ? i?.month_one
                                                                  : scaledCardThree
                                                                  ? i?.month_two
                                                                  : null;
                                                              setSelectSubscription(
                                                                i
                                                              );
                                                              payNowSpecialVip(
                                                                price,
                                                                i,
                                                                pkgMonth
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {section ===
                                                            "special" &&
                                                          upgradeSpecial ===
                                                            false &&
                                                          CurrentPackageSpecial?.name?.english.trim() ===
                                                            i?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })
                                  : autoPackageDataSpecial?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                i?.package?.name
                                                                  ?.english
                                                              }
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.package?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.package
                                                            ?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  <div>
                                                    {new Date(
                                                      i?.package?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageSpecial?.name?.english.trim() ===
                                                  i?.package?.name?.english.trim()
                                                    ? upgradeSpecial
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.package?.mrp -
                                                            i?.ammount_one) /
                                                            i?.package?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.package
                                                                    ?.mrp -
                                                                    i?.ammount_one) /
                                                                    i?.package
                                                                      ?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.package?.mrp >
                                                          0 && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month
                                                                  }{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrp
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrp !==
                                                                      i?.ammount_one && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrp !==
                                                                      i?.ammount_one
                                                                        ? i?.ammount_one
                                                                        : i
                                                                            ?.package
                                                                            ?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrp -
                                                                          i?.ammount_one) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrp -
                                                                              i?.ammount_one) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrpone >
                                                          0 && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_one
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrpone !==
                                                                      i?.ammount_two && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrpone !==
                                                                      i?.ammount_two
                                                                        ? i?.ammount_two
                                                                        : i
                                                                            ?.package
                                                                            ?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrpone -
                                                                          i?.ammount_two) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrpone -
                                                                              i?.ammount_two) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrptwo >
                                                          0 && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_two
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrptwo !==
                                                                      i?.ammount_three && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrptwo !==
                                                                      i?.ammount_three
                                                                        ? i?.ammount_three
                                                                        : i
                                                                            ?.package
                                                                            ?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrptwo -
                                                                          i?.ammount_three) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrptwo -
                                                                              i?.ammount_three) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}
                                                  <div>
                                                    {CurrentPackageSpecial ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_one
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrpone
                                                                  : i?.ammount_two
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_three
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? i?.package
                                                                    ?.month
                                                                : scaledCardTwo
                                                                ? i?.package
                                                                    ?.month_one
                                                                : scaledCardThree
                                                                ? i?.package
                                                                    ?.month_two
                                                                : null;
                                                            let pkg =
                                                              i?.package;
                                                            if (pkgMonth) {
                                                              setError(false);
                                                              handleSwitchClick();
                                                              setPkgNameSpecial(
                                                                {
                                                                  pkgMonth:
                                                                    pkgMonth,
                                                                  price: price,
                                                                  i: pkg,
                                                                }
                                                              );
                                                            } else {
                                                              setError(true);
                                                            }
                                                          }}
                                                        >
                                                          {section === "special"
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                        {error && (
                                                          <p className="fs-16 text-danger p-1">
                                                            Please select any
                                                            one Package
                                                          </p>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              section ===
                                                                "special" &&
                                                              upgradeSpecial ===
                                                                false
                                                            ) {
                                                              setUpgradeSpecial(
                                                                true
                                                              );
                                                            } else {
                                                              let price =
                                                                scaledCardOne
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrptwo
                                                                    : i?.ammount_one
                                                                  : scaledCardTwo
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrpone
                                                                    : i?.ammount_two
                                                                  : scaledCardThree
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrptwo
                                                                    : i?.ammount_three
                                                                  : null;
                                                              let pkgMonth =
                                                                scaledCardOne
                                                                  ? i?.package
                                                                      ?.month
                                                                  : scaledCardTwo
                                                                  ? i?.package
                                                                      ?.month_one
                                                                  : scaledCardThree
                                                                  ? i?.package
                                                                      ?.month_two
                                                                  : null;
                                                              let pkg =
                                                                i?.package;
                                                              setSelectSubscription(
                                                                i?.package
                                                              );
                                                              payNowSpecialVip(
                                                                price,
                                                                pkg,
                                                                pkgMonth
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {section ===
                                                            "special" &&
                                                          upgradeSpecial ===
                                                            false &&
                                                          CurrentPackageSpecial?.name?.english.trim() ===
                                                            i?.package?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })}
                                <IonFooter
                                  style={{
                                    display: "grid",
                                    position: "sticky",
                                    bottom: "0",
                                    backgroundColor: "#ef423b",
                                  }}
                                >
                                  <IonCard
                                    className="bg-none mb-1 mt-1 ion-text-center"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        color: "white",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Need Help ?
                                    </p>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onWhatsAppMessage();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      WhatsApp{" "}
                                    </IonButton>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onJsmCall();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      Call Us{" "}
                                    </IonButton>
                                  </IonCard>
                                </IonFooter>
                              </>
                            }
                          </div>
                        )}
                    {/* Vip card display */}
                    {CurrentPackageVip !== null
                      ? section === "VIP" && (
                          <div>
                            {
                              <>
                                {autoPackageDataVip?.length === 0
                                  ? VIPList?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {i?.name?.english}
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {CurrentPackageVip &&
                                                  CurrentPackageVip?.name?.english.trim() ===
                                                    i?.name?.english.trim() &&
                                                  AppContext?.user
                                                    ?.payment_verified_at === 1
                                                    ? 1 && (
                                                        <>
                                                          <div className="discountCardcontainer">
                                                            <IonCard
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {CurrentPackageVip?.package_month >
                                                                  59
                                                                    ? "Till Marriage"
                                                                    : `for ${CurrentPackageVip?.package_month} months`}
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                <h1
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    CurrentPackageVip?.amount
                                                                  }
                                                                </h1>
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  Current Plan
                                                                </h2>
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </div>
                                                        </>
                                                      )
                                                    : null}
                                                  <div>
                                                    {new Date(
                                                      i?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageVip &&
                                                  CurrentPackageVip?.name?.english.trim() ===
                                                    i?.name?.english.trim()
                                                    ? upgradeVip
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.mrp - i?.price) /
                                                            i?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.mrp -
                                                                    i?.price) /
                                                                    i?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.mrp && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month}{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹{i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrp !==
                                                                      i?.price && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrp !==
                                                                      i?.price
                                                                        ? i?.price
                                                                        : i?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrp -
                                                                          i?.price) /
                                                                          i?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrp -
                                                                              i?.price) /
                                                                              i?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrpone && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_one}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrpone !==
                                                                      i?.priceone && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrpone !==
                                                                      i?.priceone
                                                                        ? i?.priceone
                                                                        : i?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrpone -
                                                                          i?.priceone) /
                                                                          i?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrpone -
                                                                              i?.priceone) /
                                                                              i?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.mrptwo && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {i?.month_two}{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.mrptwo !==
                                                                      i?.pricetwo && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i?.mrptwo !==
                                                                      i?.pricetwo
                                                                        ? i?.pricetwo
                                                                        : i?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrptwo -
                                                                          i?.pricetwo) /
                                                                          i?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i?.mrptwo -
                                                                              i?.pricetwo) /
                                                                              i?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}

                                                  <div>
                                                    {CurrentPackageVip ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.price
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrpone
                                                                  : i?.priceone
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.mrptwo
                                                                  : i?.pricetwo
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? i?.month
                                                                : scaledCardTwo
                                                                ? i?.month_one
                                                                : scaledCardThree
                                                                ? i?.month_two
                                                                : null;
                                                            setPkgNameSpecial({
                                                              pkgMonth:
                                                                pkgMonth,
                                                              price: price,
                                                              i: i,
                                                            });
                                                          }}
                                                        >
                                                          Pay Now
                                                        </IonButton>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              CurrentPackageVip &&
                                                              section ===
                                                                "VIP" &&
                                                              AppContext?.user
                                                                ?.payment_verified_at ===
                                                                1 &&
                                                              upgradeVip ===
                                                                false
                                                            ) {
                                                              setUpgradeVip(
                                                                true
                                                              );
                                                            } else {
                                                              let price =
                                                                scaledCardOne
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrptwo
                                                                    : i?.price
                                                                  : scaledCardTwo
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrpone
                                                                    : i?.priceone
                                                                  : scaledCardThree
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.mrptwo
                                                                    : i?.pricetwo
                                                                  : null;
                                                              let pkgMonth =
                                                                scaledCardOne
                                                                  ? i?.month
                                                                  : scaledCardTwo
                                                                  ? i?.month_one
                                                                  : scaledCardThree
                                                                  ? i?.month_two
                                                                  : null;
                                                              setSelectSubscription(
                                                                i
                                                              );
                                                              payNowSpecialVip(
                                                                price,
                                                                i,
                                                                pkgMonth
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {CurrentPackageVip &&
                                                          section === "VIP" &&
                                                          AppContext?.user
                                                            ?.payment_verified_at ===
                                                            1 &&
                                                          upgradeVip ===
                                                            false &&
                                                          CurrentPackageVip?.name?.english.trim() ===
                                                            i?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })
                                  : autoPackageDataVip?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard className="subscriptionCardParent">
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                i?.package?.name
                                                                  ?.english
                                                              }
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.package?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.package
                                                            ?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {CurrentPackageVip &&
                                                  CurrentPackageVip?.name?.english.trim() ===
                                                    i?.package?.name?.english.trim() &&
                                                  AppContext?.user
                                                    ?.payment_verified_at === 1
                                                    ? 1 && (
                                                        <>
                                                          <div className="discountCardcontainer">
                                                            <IonCard
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {CurrentPackageVip?.package_month >
                                                                  59
                                                                    ? "Till Marriage"
                                                                    : `for ${CurrentPackageVip?.package_month} months`}
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                <h1
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    CurrentPackageVip?.amount
                                                                  }
                                                                </h1>
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  Current Plan
                                                                </h2>
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </div>
                                                        </>
                                                      )
                                                    : null}
                                                  <div>
                                                    {new Date(
                                                      i?.package?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  {(CurrentPackageVip &&
                                                  CurrentPackageVip?.name?.english.trim() ===
                                                    i?.package?.name?.english.trim()
                                                    ? upgradeVip
                                                    : 1) && (
                                                    <>
                                                      {parseFloat(
                                                        (
                                                          ((i?.package?.mrp -
                                                            i?.ammount_one) /
                                                            i?.package?.mrp) *
                                                          100
                                                        ).toFixed(1)
                                                      ) !== 0 && (
                                                        <div className="discountCardcontainer">
                                                          <IonCard
                                                            className="discountCard"
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                            <p
                                                              style={{
                                                                marginTop:
                                                                  "2px",
                                                              }}
                                                            >
                                                              Upto{" "}
                                                              {parseFloat(
                                                                (
                                                                  ((i?.package
                                                                    ?.mrp -
                                                                    i?.ammount_one) /
                                                                    i?.package
                                                                      ?.mrp) *
                                                                  100
                                                                ).toFixed(1)
                                                              )}
                                                              % Discount
                                                            </p>
                                                            <div
                                                              style={{
                                                                height: "38px",
                                                                width: "38px",
                                                                transform:
                                                                  "skew(25deg)",
                                                              }}
                                                            >
                                                              <img
                                                                loading="lazy"
                                                                src={partyPop}
                                                                style={{
                                                                  maxHeight: 24,
                                                                  objectFit:
                                                                    "cover",
                                                                  marginTop:
                                                                    "4px",
                                                                }}
                                                              />
                                                            </div>
                                                          </IonCard>
                                                        </div>
                                                      )}
                                                      <IonSlides
                                                        pager={true}
                                                        options={slideOpts}
                                                        className="slides-height-md-lg"
                                                        style={{
                                                          height: "186px",
                                                          marginTop: "-26px",
                                                          marginBottom: "-10px",
                                                        }}
                                                      >
                                                        {i?.package?.mrp && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardOneScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardOne ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month
                                                                  }{" "}
                                                                  Month
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrp
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrp !==
                                                                      i?.ammount_one && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrp
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrp !==
                                                                      i?.ammount_one
                                                                        ? i?.ammount_one
                                                                        : i
                                                                            ?.package
                                                                            ?.mrp}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrp -
                                                                          i?.ammount_one) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrp -
                                                                              i?.ammount_one) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrp) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrpone && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardTwoScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardTwo ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_one
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrpone
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrpone !==
                                                                      i?.ammount_two && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrpone
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrpone !==
                                                                      i?.ammount_two
                                                                        ? i?.ammount_two
                                                                        : i
                                                                            ?.package
                                                                            ?.mrpone}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrpone -
                                                                          i?.ammount_two) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrpone -
                                                                              i?.ammount_two) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrpone) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                        {i?.package?.mrptwo && (
                                                          <IonSlide>
                                                            <IonCard
                                                              onClick={() => {
                                                                toggleCardThreeScale(
                                                                  i?.package?.id
                                                                );
                                                                setSelectSubscription(
                                                                  i?.package
                                                                );
                                                              }}
                                                              style={{
                                                                height: "114px",
                                                                width: "160px",
                                                                borderRadius:
                                                                  "12px",
                                                                textAlign:
                                                                  "center",
                                                                ...(scaledCardThree ===
                                                                  i?.package
                                                                    ?.id && {
                                                                  transform:
                                                                    "scale(1.1)",
                                                                }),
                                                                border:
                                                                  SelectSubscription?.id ===
                                                                    i?.package
                                                                      ?.id &&
                                                                  "1px solid #ffa500",
                                                              }}
                                                            >
                                                              <IonCardHeader className="planCard">
                                                                <IonCardSubtitle
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  {
                                                                    i?.package
                                                                      ?.month_two
                                                                  }{" "}
                                                                  Months
                                                                </IonCardSubtitle>
                                                              </IonCardHeader>
                                                              <IonCardContent>
                                                                {AppContext.user
                                                                  .no_offer_for_user ? (
                                                                  <span className="d-block my-4">
                                                                    <h2
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      }
                                                                      .00
                                                                    </h2>
                                                                    {/* <h3
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      0% off
                                                                    </h3> */}
                                                                  </span>
                                                                ) : (
                                                                  <>
                                                                    {i?.package
                                                                      ?.mrptwo !==
                                                                      i?.ammount_three && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        <s>
                                                                          {" "}
                                                                          ₹
                                                                          {
                                                                            i
                                                                              ?.package
                                                                              ?.mrptwo
                                                                          }
                                                                          .00{" "}
                                                                        </s>{" "}
                                                                      </h3>
                                                                    )}
                                                                    <h2
                                                                      className="fs-16"
                                                                      style={{
                                                                        color:
                                                                          "black",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      ₹
                                                                      {i
                                                                        ?.package
                                                                        ?.mrptwo !==
                                                                      i?.ammount_three
                                                                        ? i?.ammount_three
                                                                        : i
                                                                            ?.package
                                                                            ?.mrptwo}
                                                                      .00
                                                                    </h2>
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrptwo -
                                                                          i?.ammount_three) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    ) !== 0 && (
                                                                      <h3
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {parseFloat(
                                                                          (
                                                                            ((i
                                                                              ?.package
                                                                              ?.mrptwo -
                                                                              i?.ammount_three) /
                                                                              i
                                                                                ?.package
                                                                                ?.mrptwo) *
                                                                            100
                                                                          ).toFixed(
                                                                            1
                                                                          )
                                                                        )}
                                                                        % Off{" "}
                                                                      </h3>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </IonCardContent>
                                                            </IonCard>
                                                          </IonSlide>
                                                        )}
                                                      </IonSlides>
                                                    </>
                                                  )}

                                                  <div>
                                                    {CurrentPackageVip ===
                                                    null ? (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            let price =
                                                              scaledCardOne
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_one
                                                                : scaledCardTwo
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrpone
                                                                  : i?.ammount_two
                                                                : scaledCardThree
                                                                ? AppContext
                                                                    .user
                                                                    .no_offer_for_user
                                                                  ? i?.package
                                                                      ?.mrptwo
                                                                  : i?.ammount_three
                                                                : null;
                                                            let pkgMonth =
                                                              scaledCardOne
                                                                ? "1 Month"
                                                                : scaledCardTwo
                                                                ? "3 Months"
                                                                : scaledCardThree
                                                                ? "6 Months"
                                                                : null;
                                                            let pkg =
                                                              i?.package;
                                                            setPkgNameSpecial({
                                                              pkgMonth:
                                                                pkgMonth,
                                                              price: price,
                                                              i: pkg,
                                                            });
                                                          }}
                                                        >
                                                          Pay Now
                                                        </IonButton>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="btnFill"
                                                        style={{
                                                          margin:
                                                            "10px 10px 20px 10px",
                                                        }}
                                                      >
                                                        <IonButton
                                                          type="submit"
                                                          expand="block"
                                                          onClick={() => {
                                                            if (
                                                              CurrentPackageVip &&
                                                              section ===
                                                                "VIP" &&
                                                              AppContext?.user
                                                                ?.payment_verified_at ===
                                                                1 &&
                                                              upgradeVip ===
                                                                false
                                                            ) {
                                                              setUpgradeVip(
                                                                true
                                                              );
                                                            } else {
                                                              let price =
                                                                scaledCardOne
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrptwo
                                                                    : i?.ammount_one
                                                                  : scaledCardTwo
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrpone
                                                                    : i?.ammount_two
                                                                  : scaledCardThree
                                                                  ? AppContext
                                                                      .user
                                                                      .no_offer_for_user
                                                                    ? i?.package
                                                                        ?.mrptwo
                                                                    : i?.ammount_three
                                                                  : null;

                                                              let pkgMonth =
                                                                scaledCardOne
                                                                  ? i?.package
                                                                      ?.month
                                                                  : scaledCardTwo
                                                                  ? i?.package
                                                                      ?.month_one
                                                                  : scaledCardThree
                                                                  ? i?.package
                                                                      ?.month_two
                                                                  : null;
                                                              let pkg =
                                                                i?.package;
                                                              setSelectSubscription(
                                                                i?.package
                                                              );
                                                              payNowSpecialVip(
                                                                price,
                                                                pkg,
                                                                pkgMonth
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {CurrentPackageVip &&
                                                          section === "VIP" &&
                                                          AppContext?.user
                                                            ?.payment_verified_at ===
                                                            1 &&
                                                          upgradeVip ===
                                                            false &&
                                                          CurrentPackageVip?.name?.english.trim() ===
                                                            i?.package?.name?.english.trim()
                                                            ? "Upgrade Plan"
                                                            : "Pay Now"}
                                                        </IonButton>
                                                      </div>
                                                    )}
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })}
                                <IonFooter
                                  style={{
                                    display: "grid",
                                    position: "sticky",
                                    bottom: "0",
                                    backgroundColor: "#ef423b",
                                  }}
                                >
                                  <IonCard
                                    className="bg-none mb-1 mt-1 ion-text-center"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        color: "white",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Need Help ?
                                    </p>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onWhatsAppMessage();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      WhatsApp{" "}
                                    </IonButton>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onJsmCall();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      Call Us{" "}
                                    </IonButton>
                                  </IonCard>
                                </IonFooter>
                              </>
                            }
                          </div>
                        )
                      : section === "VIP" && (
                          <div>
                            {
                              <>
                                {autoPackageDataVip?.length === 0
                                  ? VIPList?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard
                                              className="subscriptionCardParent"
                                              onClick={() => {
                                                setShowMoreText(false);
                                                setSelectSubscription(i);
                                              }}
                                              style={{
                                                border:
                                                  SelectSubscription?.id ===
                                                    i?.id &&
                                                  "1px solid #ffa500",
                                              }}
                                            >
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {i?.name?.english}
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {parseFloat(
                                                    (
                                                      ((i?.mrp - i?.price) /
                                                        i?.mrp) *
                                                      100
                                                    ).toFixed(1)
                                                  ) !== 0 && (
                                                    <div className="discountCardcontainer">
                                                      <IonCard
                                                        className="discountCard"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                            transform:
                                                              "skew(25deg)",
                                                          }}
                                                        >
                                                          <img
                                                            loading="lazy"
                                                            src={partyPop}
                                                            style={{
                                                              maxHeight: 24,
                                                              objectFit:
                                                                "cover",
                                                              marginTop: "4px",
                                                            }}
                                                          />
                                                        </div>
                                                        <p
                                                          style={{
                                                            marginTop: "2px",
                                                          }}
                                                        >
                                                          Upto{" "}
                                                          {parseFloat(
                                                            (
                                                              ((i?.mrp -
                                                                i?.price) /
                                                                i?.mrp) *
                                                              100
                                                            ).toFixed(1)
                                                          )}
                                                          % Discount
                                                        </p>
                                                        <div
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                            transform:
                                                              "skew(25deg)",
                                                          }}
                                                        >
                                                          <img
                                                            loading="lazy"
                                                            src={partyPop}
                                                            style={{
                                                              maxHeight: 24,
                                                              objectFit:
                                                                "cover",
                                                              marginTop: "4px",
                                                            }}
                                                          />
                                                        </div>
                                                      </IonCard>
                                                    </div>
                                                  )}
                                                  <div>
                                                    {new Date(
                                                      i?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  <IonSlides
                                                    pager={true}
                                                    options={slideOpts}
                                                    style={{
                                                      height: "186px",
                                                      marginTop: "-26px",
                                                      marginBottom: "-10px",
                                                    }}
                                                  >
                                                    {i?.mrp && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardOneScale(
                                                              i?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardOne ===
                                                              i?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {i?.month} Month
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹{i?.mrp}.00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.mrp !==
                                                                  i?.price && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹{i?.mrp}
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.mrp !==
                                                                  i?.price
                                                                    ? i?.price
                                                                    : i?.mrp}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.mrp -
                                                                      i?.price) /
                                                                      i?.mrp) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrp -
                                                                          i?.price) /
                                                                          i?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                    {i?.mrpone && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardTwoScale(
                                                              i?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardTwo ===
                                                              i?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {i?.month_one}{" "}
                                                              Months
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹{i?.mrpone}
                                                                  .00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.mrpone !==
                                                                  i?.priceone && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹
                                                                      {
                                                                        i?.mrpone
                                                                      }
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.mrpone !==
                                                                  i?.priceone
                                                                    ? i?.priceone
                                                                    : i?.mrpone}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.mrpone -
                                                                      i?.priceone) /
                                                                      i?.mrpone) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrpone -
                                                                          i?.priceone) /
                                                                          i?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                    {i?.mrptwo && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardThreeScale(
                                                              i?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardThree ===
                                                              i?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {i?.month_two}{" "}
                                                              Months
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹{i?.mrptwo}
                                                                  .00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.mrptwo !==
                                                                  i?.pricetwo && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹
                                                                      {
                                                                        i?.mrptwo
                                                                      }
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.mrptwo !==
                                                                  i?.pricetwo
                                                                    ? i?.pricetwo
                                                                    : i?.mrptwo}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.mrptwo -
                                                                      i?.pricetwo) /
                                                                      i?.mrptwo) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i?.mrptwo -
                                                                          i?.pricetwo) /
                                                                          i?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                  </IonSlides>
                                                  <div>
                                                    <div
                                                      className="btnFill"
                                                      style={{
                                                        margin:
                                                          "10px 10px 20px 10px",
                                                      }}
                                                    >
                                                      <IonButton
                                                        type="submit"
                                                        expand="block"
                                                        onClick={() => {
                                                          let price =
                                                            scaledCardOne
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.mrptwo
                                                                : i?.price
                                                              : scaledCardTwo
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.mrpone
                                                                : i?.priceone
                                                              : scaledCardThree
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.mrptwo
                                                                : i?.pricetwo
                                                              : null;
                                                          let pkgMonth =
                                                            scaledCardOne
                                                              ? i?.month
                                                              : scaledCardTwo
                                                              ? i?.month_one
                                                              : scaledCardThree
                                                              ? i?.month_two
                                                              : null;
                                                          if (price) {
                                                            setVipError(false);
                                                            setSelectSubscription(
                                                              i
                                                            );
                                                            payNowSpecialVip(
                                                              price,
                                                              i,
                                                              pkgMonth
                                                            );
                                                          } else {
                                                            setVipError(true);
                                                          }
                                                        }}
                                                      >
                                                        Pay Now
                                                      </IonButton>
                                                      {errorVip && (
                                                        <p className="fs-16 text-danger p-1">
                                                          Please select any one
                                                          Package
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })
                                  : autoPackageDataVip?.map((i: any) => {
                                      return (
                                        <div key={i?.id}>
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "flex",
                                              justifyContent: "center",
                                              top: "10px",
                                            }}
                                          >
                                            <IonCard
                                              className="subscriptionCardParent"
                                              onClick={() => {
                                                setShowMoreText(false);
                                                setSelectSubscription(
                                                  i?.package
                                                );
                                              }}
                                              style={{
                                                border:
                                                  SelectSubscription?.id ===
                                                    i?.id &&
                                                  "1px solid #ffa500",
                                              }}
                                            >
                                              <IonRow>
                                                <IonCol size="12">
                                                  <div className="specialOfferCard">
                                                    <IonItem className="subscriptionCard">
                                                      <IonLabel>
                                                        <div
                                                          style={{
                                                            color: "#1a2a38",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              height: "38px",
                                                              width: "38px",
                                                            }}
                                                          >
                                                            <img
                                                              loading="lazy"
                                                              src={crownPic}
                                                              style={{
                                                                maxHeight: 36,
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                            />
                                                          </div>
                                                          <h1>
                                                            <b
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                i?.package?.name
                                                                  ?.english
                                                              }
                                                            </b>
                                                          </h1>
                                                        </div>
                                                      </IonLabel>
                                                    </IonItem>
                                                  </div>

                                                  <div>
                                                    <p
                                                      style={{
                                                        textAlign: "center",
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {i?.package?.header}
                                                    </p>
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        top: "10px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "2",
                                                        }}
                                                      >
                                                        <IonIcon
                                                          size="small"
                                                          slot="icon-only"
                                                          icon={heart}
                                                          style={{
                                                            color: "red",
                                                            verticalAlign:
                                                              "middle",
                                                          }}
                                                        ></IonIcon>
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        height: "28px",
                                                        width: "276px",
                                                        marginLeft: "39px",
                                                        marginTop: "-12px",
                                                      }}
                                                    >
                                                      <img
                                                        loading="lazy"
                                                        src={heartline}
                                                        style={{
                                                          maxHeight: 24,
                                                          objectFit: "cover",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    </div>
                                                    <IonLabel
                                                      className="subscriptionDesc"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          i?.package
                                                            ?.description
                                                            .english,
                                                      }}
                                                    ></IonLabel>
                                                  </div>
                                                  {parseFloat(
                                                    (
                                                      ((i?.package?.mrp -
                                                        i?.ammount_one) /
                                                        i?.package?.mrp) *
                                                      100
                                                    ).toFixed(1)
                                                  ) !== 0 && (
                                                    <div className="discountCardcontainer">
                                                      <IonCard
                                                        className="discountCard"
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                            transform:
                                                              "skew(25deg)",
                                                          }}
                                                        >
                                                          <img
                                                            loading="lazy"
                                                            src={partyPop}
                                                            style={{
                                                              maxHeight: 24,
                                                              objectFit:
                                                                "cover",
                                                              marginTop: "4px",
                                                            }}
                                                          />
                                                        </div>
                                                        <p
                                                          style={{
                                                            marginTop: "2px",
                                                          }}
                                                        >
                                                          Upto{" "}
                                                          {parseFloat(
                                                            (
                                                              ((i?.package
                                                                ?.mrp -
                                                                i?.ammount_one) /
                                                                i?.package
                                                                  ?.mrp) *
                                                              100
                                                            ).toFixed(1)
                                                          )}
                                                          % Discount
                                                        </p>
                                                        <div
                                                          style={{
                                                            height: "38px",
                                                            width: "38px",
                                                            transform:
                                                              "skew(25deg)",
                                                          }}
                                                        >
                                                          <img
                                                            loading="lazy"
                                                            src={partyPop}
                                                            style={{
                                                              maxHeight: 24,
                                                              objectFit:
                                                                "cover",
                                                              marginTop: "4px",
                                                            }}
                                                          />
                                                        </div>
                                                      </IonCard>
                                                    </div>
                                                  )}
                                                  <div>
                                                    {new Date(
                                                      i?.package?.offerExpireDate
                                                    ).getTime() > 0 && (
                                                      <IonCard className="offerEnd px-3">
                                                        <div>
                                                          <IonLabel>
                                                            <h1>OFFER</h1>
                                                            <IonLabel></IonLabel>
                                                            <h2
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              ENDS IN
                                                            </h2>
                                                          </IonLabel>
                                                        </div>
                                                        <div>
                                                          <IonBadge
                                                            style={{
                                                              backgroundColor:
                                                                "#f9ebd1",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                marginBottom:
                                                                  "0",
                                                                fontWeight:
                                                                  "600",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <Countdown
                                                                date={10000}
                                                                renderer={
                                                                  renderer
                                                                }
                                                              />
                                                            </h4>
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "9px",
                                                                }}
                                                              >
                                                                DAYS
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "13px",
                                                                }}
                                                              >
                                                                HOUR
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                              >
                                                                MIN
                                                              </h6>
                                                              <h6
                                                                className="offerEndTime"
                                                                style={{
                                                                  marginLeft:
                                                                    "19px",
                                                                }}
                                                              >
                                                                SEC
                                                              </h6>
                                                            </div>
                                                          </IonBadge>
                                                        </div>
                                                      </IonCard>
                                                    )}
                                                  </div>

                                                  <IonSlides
                                                    pager={true}
                                                    options={slideOpts}
                                                    style={{
                                                      height: "186px",
                                                      marginTop: "-26px",
                                                      marginBottom: "-10px",
                                                    }}
                                                  >
                                                    {i?.package?.mrp && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardOneScale(
                                                              i?.package?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardOne ===
                                                              i?.package
                                                                ?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {
                                                                i?.package
                                                                  ?.month
                                                              }{" "}
                                                              Month
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    i?.package
                                                                      ?.mrp
                                                                  }
                                                                  .00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.package
                                                                  ?.mrp !==
                                                                  i?.ammount_one && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrp
                                                                      }
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.package
                                                                    ?.mrp !==
                                                                  i?.ammount_one
                                                                    ? i?.ammount_one
                                                                    : i?.package
                                                                        ?.mrp}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.package
                                                                      ?.mrp -
                                                                      i?.ammount_one) /
                                                                      i?.package
                                                                        ?.mrp) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrp -
                                                                          i?.ammount_one) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrp) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                    {i?.package?.mrpone && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardTwoScale(
                                                              i?.package?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardTwo ===
                                                              i?.package
                                                                ?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {
                                                                i?.package
                                                                  ?.month_one
                                                              }{" "}
                                                              Months
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    i?.package
                                                                      ?.mrpone
                                                                  }
                                                                  .00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.package
                                                                  ?.mrpone !==
                                                                  i?.ammount_two && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrpone
                                                                      }
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.package
                                                                    ?.mrpone !==
                                                                  i?.ammount_two
                                                                    ? i?.ammount_two
                                                                    : i?.package
                                                                        ?.mrpone}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.package
                                                                      ?.mrpone -
                                                                      i?.ammount_two) /
                                                                      i?.package
                                                                        ?.mrpone) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrpone -
                                                                          i?.ammount_two) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrpone) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                    {i?.package?.mrptwo && (
                                                      <IonSlide>
                                                        <IonCard
                                                          onClick={() =>
                                                            toggleCardThreeScale(
                                                              i?.package?.id
                                                            )
                                                          }
                                                          style={{
                                                            height: "114px",
                                                            width: "160px",
                                                            borderRadius:
                                                              "12px",
                                                            textAlign: "center",
                                                            ...(scaledCardThree ===
                                                              i?.package
                                                                ?.id && {
                                                              transform:
                                                                "scale(1.1)",
                                                            }),
                                                          }}
                                                        >
                                                          <IonCardHeader className="planCard">
                                                            <IonCardSubtitle
                                                              style={{
                                                                color: "white",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {
                                                                i?.package
                                                                  ?.month_two
                                                              }{" "}
                                                              Months
                                                            </IonCardSubtitle>
                                                          </IonCardHeader>
                                                          <IonCardContent>
                                                            {AppContext.user
                                                              .no_offer_for_user ? (
                                                              <span className="d-block my-4">
                                                                <h2
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {
                                                                    i?.package
                                                                      ?.mrptwo
                                                                  }
                                                                  .00
                                                                </h2>
                                                                {/* <h3
                                                                  style={{
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  0% off
                                                                </h3> */}
                                                              </span>
                                                            ) : (
                                                              <>
                                                                {i?.package
                                                                  ?.mrptwo !==
                                                                  i?.ammount_three && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    <s>
                                                                      {" "}
                                                                      ₹
                                                                      {
                                                                        i
                                                                          ?.package
                                                                          ?.mrptwo
                                                                      }
                                                                      .00{" "}
                                                                    </s>{" "}
                                                                  </h3>
                                                                )}
                                                                <h2
                                                                  className="fs-16"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                >
                                                                  ₹
                                                                  {i?.package
                                                                    ?.mrptwo !==
                                                                  i?.ammount_three
                                                                    ? i?.ammount_three
                                                                    : i?.package
                                                                        ?.mrptwo}
                                                                  .00
                                                                </h2>
                                                                {parseFloat(
                                                                  (
                                                                    ((i?.package
                                                                      ?.mrptwo -
                                                                      i?.ammount_three) /
                                                                      i?.package
                                                                        ?.mrptwo) *
                                                                    100
                                                                  ).toFixed(1)
                                                                ) !== 0 && (
                                                                  <h3
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {parseFloat(
                                                                      (
                                                                        ((i
                                                                          ?.package
                                                                          ?.mrptwo -
                                                                          i?.ammount_three) /
                                                                          i
                                                                            ?.package
                                                                            ?.mrptwo) *
                                                                        100
                                                                      ).toFixed(
                                                                        1
                                                                      )
                                                                    )}
                                                                    % Off{" "}
                                                                  </h3>
                                                                )}
                                                              </>
                                                            )}
                                                          </IonCardContent>
                                                        </IonCard>
                                                      </IonSlide>
                                                    )}
                                                  </IonSlides>
                                                  <div>
                                                    <div
                                                      className="btnFill"
                                                      style={{
                                                        margin:
                                                          "10px 10px 20px 10px",
                                                      }}
                                                    >
                                                      <IonButton
                                                        type="submit"
                                                        expand="block"
                                                        onClick={() => {
                                                          let price =
                                                            scaledCardOne
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.package
                                                                    ?.mrptwo
                                                                : i?.ammount_one
                                                              : scaledCardTwo
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.package
                                                                    ?.mrpone
                                                                : i?.ammount_two
                                                              : scaledCardThree
                                                              ? AppContext.user
                                                                  .no_offer_for_user
                                                                ? i?.package
                                                                    ?.mrptwo
                                                                : i?.ammount_three
                                                              : null;
                                                          let pkg = i?.package;
                                                          let pkgMonth =
                                                            scaledCardOne
                                                              ? i?.package
                                                                  ?.month
                                                              : scaledCardTwo
                                                              ? i?.package
                                                                  ?.month_one
                                                              : scaledCardThree
                                                              ? i?.package
                                                                  ?.month_two
                                                              : null;
                                                          if (price) {
                                                            setVipError(false);
                                                            setSelectSubscription(
                                                              i?.package
                                                            );
                                                            payNowSpecialVip(
                                                              price,
                                                              pkg,
                                                              pkgMonth
                                                            );
                                                          } else {
                                                            setVipError(true);
                                                          }
                                                        }}
                                                      >
                                                        Pay Now
                                                      </IonButton>
                                                      {errorVip && (
                                                        <p className="fs-16 text-danger p-1">
                                                          Please select any one
                                                          Package
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </IonCol>
                                              </IonRow>
                                            </IonCard>
                                          </div>
                                          {/* } */}
                                        </div>
                                      );
                                    })}
                                <IonFooter
                                  style={{
                                    display: "grid",
                                    position: "fixed",
                                    bottom: "0",
                                    backgroundColor: "#ef423b",
                                    left: "0",
                                    right: "0",
                                  }}
                                >
                                  <IonCard
                                    className="bg-none mb-1 mt-1 ion-text-center"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        fontWeight: "bold",
                                        color: "white",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Need Help ?
                                    </p>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onWhatsAppMessage();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      WhatsApp{" "}
                                    </IonButton>
                                    <IonButton
                                      size="small"
                                      className="outline-btn ml-10 fs-14 font-weight-500"
                                      fill="solid"
                                      color="primary"
                                      style={{
                                        border: "2px solid",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => {
                                        onJsmCall();
                                      }}
                                    >
                                      <IonIcon
                                        size="small"
                                        slot="icon-only"
                                        icon={callSharp}
                                        style={{
                                          color: "#fff",
                                          verticalAlign: "middle",
                                        }}
                                      ></IonIcon>
                                      Call Us{" "}
                                    </IonButton>
                                  </IonCard>
                                </IonFooter>
                              </>
                            }
                          </div>
                        )}
                  </>
                )}
              </>
            )}
          </div>
        </IonContent>

        {
          <IonModal
            isOpen={showBasicPaymentModal}
            onDidDismiss={() => setShowBasicPaymentModal(false)}
            style={{ "--border-radius": "20px 20px 0 0", bottom: "0" }}
            initialBreakpoint={0.4}
            backdropBreakpoint={0.2}
          >
            <IonContent
              className="PkgModalBtn"
              style={{ position: "fixed", bottom: "0" }}
            >
              <IonGrid>
                <IonRow className="ion-padding-start ion-padding-end">
                  <IonCol>
                    <IonLabel>
                      <h2 className="fs-18 fw-bold pkgTitlePayment">
                        <b>
                          {pkgNameSpecial?.pkgMonth
                            ? pkgNameSpecial?.i?.name?.english
                            : ""}
                        </b>
                      </h2>
                      <p style={{ color: "#ef423b" }}>
                        {pkgNameSpecial?.pkgMonth} Months
                      </p>
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IonLabel>
                      <h2 className="fs-16 fw-bold">
                        <b>
                          {pkgNameSpecial?.price
                            ? "₹" + pkgNameSpecial?.price
                            : ""}
                        </b>
                      </h2>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <hr style={{ marginTop: "0", marginBottom: "0" }} />
                <IonRow>
                  <IonLabel className="ion-text-center w-100" color="success">
                    <h2 className="fs-16 fw-bold">Select Basic Package</h2>
                  </IonLabel>
                </IonRow>
                {pkgNameSpecial?.pkgMonth ? (
                  <div>
                    {Subscription?.map((i: any) => (
                      <IonRadioGroup
                        key={i?.id}
                        allowEmptySelection={true}
                        value={selectedValue}
                      >
                        <IonRow
                          className="ion-padding-start my-2 ion-padding-end"
                          onClick={() => {
                            let price = i?.price + pkgNameSpecial?.price;
                            let data = i;
                            if (selectedValue === price) {
                              setSelectedValue(null);
                            } else {
                              setSelectedValue(price);
                            }
                            setSelectedPayment({ price, data });
                          }}
                        >
                          <IonCol>
                            <IonLabel>
                              <h2 className="fs-16 fw-bold pkgTitlePayment">
                                <b>{i?.name?.english}</b>
                              </h2>
                              <p style={{ color: "#ef423b" }}>
                                {i?.name?.english === "Platinum"
                                  ? "Till Marriage"
                                  : i?.name?.english === "Gold"
                                  ? "For 12 Months"
                                  : i?.name?.english === "Silver"
                                  ? "For 6 Months"
                                  : ""}
                              </p>
                            </IonLabel>
                          </IonCol>
                          <IonCol className="ion-text-end">₹{i?.price}</IonCol>
                          <IonCol className="ion-text-end">
                            <IonRadio
                              value={i?.price + pkgNameSpecial?.price}
                              onClick={(e: any) => {
                                let price = e.target.value;
                                let data = i;
                                setSelectedValue(price);
                                setSelectedPayment({ price, data });
                              }}
                            />
                          </IonCol>
                        </IonRow>
                      </IonRadioGroup>
                    ))}
                  </div>
                ) : (
                  <div>
                    <h2 className="fs-18 fw-bold pkgTitlePayment text-danger text-center">
                      <b>{`Choose a ${pkgNameSpecial?.i?.name?.english} plan first!`}</b>
                    </h2>
                  </div>
                )}
                <hr style={{ marginTop: "0", marginBottom: "0" }} />
              </IonGrid>
              {pkgNameSpecial?.pkgMonth && (
                <IonButton
                  className="mx-0"
                  expand="full"
                  onClick={() => {
                    const priceToPay = selectedValue || pkgNameSpecial?.price;
                    setSelectSubscription(selectedPayment?.data);
                    payNowSpecialVip(
                      priceToPay,
                      pkgNameSpecial?.i,
                      pkgNameSpecial?.pkgMonth
                    );
                  }}
                >
                  Pay Now
                </IonButton>
              )}
            </IonContent>
          </IonModal>
        }
      </IonModal>
      <IonModal
        id="example-modal"
        ref={modal}
        isOpen={helpModal}
        trigger="open-modal"
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        onDidDismiss={() => {
          setHelpModal(false);
        }}
        onWillDismiss={() => {
          setHelpModal(false);
        }}
      >
        <IonHeader className="px-1">
          <IonToolbar>
            <p className="gradient m-0">जय जिनेन्द्र बंधुओ</p>
            <div slot="end" className="backBtn">
              <IonButton
                type="submit"
                className="px-1"
                onClick={() => {
                  setHelpModal(false);
                }}
              >
                <IonIcon icon={closeOutline} color="light" />
              </IonButton>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <div className="ion-text-center p-3">
              <p className="fs-12">
                <b>
                  आप पंजीकरण शुल्क का भुकतान हमारे बैंक खाता विवरण और फोनपे /
                  पेटीएम / गूगलपे नंबर के द्वारा कर सकते हैं |
                </b>
              </p>
              <IonCard className="card subscriptionCardParent ion-text-center helpSec">
                <IonItem>
                  <IonAvatar className="ion-margin-end my-0">
                    <img
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      src={paytm}
                    />
                  </IonAvatar>
                  <IonLabel className="m-0">
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b>9171370325</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard(9171370325);
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonAvatar className="ion-margin-end my-0">
                    <img
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      src={phonepe}
                    />
                  </IonAvatar>
                  <IonLabel className="m-0">
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b> 9171370325</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard(9171370325);
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonAvatar className="ion-margin-end my-0">
                    <img
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      src={googlepay}
                    />
                  </IonAvatar>
                  <IonLabel className="m-0">
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b>9171370325</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard(9171370325);
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonAvatar className="ion-margin-end my-0">
                    <img
                      loading="lazy"
                      style={{ objectFit: "contain" }}
                      src={bank}
                    />
                  </IonAvatar>
                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 12, margin: 0 }}>
                      <b>Bank Details :</b>
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 10, margin: 0 }}>
                      <b>Bank Name</b>
                    </p>
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b> Union Bank of India</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard("Union Bank of India");
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 10, margin: 0 }}>
                      <b>खाता धारक का नाम </b>
                    </p>
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b>Jain Shadi Milan</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard("Jain Shadi Milan");
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 10, margin: 0 }}>
                      <b>खाता संख्या</b>
                    </p>
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b>109621010000014</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard("109621010000014");
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <IonItem>
                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 10, margin: 0 }}>
                      <b>IFSC कोड</b>
                    </p>
                    <p style={{ color: "#EF423B", fontSize: 14, margin: 0 }}>
                      <b>UBIN0910961</b>
                    </p>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard("UBIN0910961");
                      checkClipboard();
                    }}
                  >
                    {" "}
                    <IonIcon
                      icon={copyOutline}
                      color="primary"
                      style={{ verticalAlign: "sub", fontSize: "18px" }}
                    />
                  </IonButton>
                </IonItem>
                <hr className="mt-1 mb-0" style={{ color: "#EF423B" }} />
                <IonItem
                  className="my-1"
                  onClick={() => {
                    setOpenImage(true);
                  }}
                >
                  <IonAvatar className="ion-margin-end my-0">
                    <img loading="lazy" src={qrcode} />
                  </IonAvatar>

                  <IonLabel className="m-0">
                    <p style={{ color: "#000", fontSize: 12, margin: 0 }}>
                      <b>Show QR Code</b>
                    </p>
                  </IonLabel>
                </IonItem>
                {openImage && (
                  <Lightbox
                    clickOutsideToClose={false}
                    imageTitle="Qr Code"
                    imageLoadErrorMessage="This image failed to load"
                    onImageLoad={() => {
                      window.dispatchEvent(new Event("resize"));
                    }}
                    mainSrc={qr}
                    onCloseRequest={() => setOpenImage(false)}
                  />
                )}
              </IonCard>

              <p
                className=" mt-3 mx-3"
                style={{ fontSize: 13, lineHeight: 1.8 }}
              >
                <b>
                  <span style={{ color: "#EF423B" }}>NOTE</span> : रजिस्ट्रेशन
                  शुक्ल भेजने के बाद हमारे व्हाट्स एप्प नंबर{" "}
                  <span style={{ color: "#EF423B" }}> (+91-7610447511)</span> पर
                  पेमेंट सफलतापूर्वक (Successful) का Screenshot और रजिस्टर्ड
                  मोबाईल नंबर जरूर भेजें, जिससे हम पेमेंट एड के देंगे एप्प पर।
                </b>
              </p>
              <div className="ion-text-center">
                <p className="fs-14 my-1">
                  <b>🙏 धन्यवाद 🙏</b>
                </p>
                <b className="fs-14">
                  <span style={{ color: "#EF423B" }}>जैन शादी मिलन</span>
                </b>
              </div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={async () => {
                  let mobile = AppContext?.user?.payment_verified_at
                    ? After
                    : Before;
                  await CallNumber.callNumber(String(mobile), true);
                  // await CallNumber.callNumber("7610447511", true);
                }}
              >
                <IonIcon
                  icon={callSharp}
                  color="light"
                  style={{ verticalAlign: "middle", marginRight: 8 }}
                />
                Call Now
              </button>
            </div>
          </IonList>
        </IonContent>
      </IonModal>
      {/* automatic call modal */}
      <IonModal
        id="example-modalsss"
        ref={modal}
        isOpen={callModal}
        trigger="open-modalsss"
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
        onDidDismiss={() => {
          setClickIndex(clickIndex + 1);
          setCallModal(false);
        }}
        onWillDismiss={() => {
          setCallModal(false);
        }}
      >
        <div>
          <div
            className="p-2"
            onClick={(event) => {
              event.stopPropagation();
              setCallModal(false);
            }}
          >
            <IonIcon
              icon={arrowBack}
              style={{ fontSize: 24 }}
              color="light"
              className="p-2"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "85vh",
              alignItems: "center",
            }}
          >
            <div className="ion-text-center text-white">
              <h5 className="mb-4 mx-2">
                <b style={{ lineHeight: 1.7 }}>
                  यदि आपको रजिस्ट्रेशन शुल्क जमा करने में असुविधा हो रही है, तो
                  हमारी टीम को अभी कॉल करें।
                </b>
                <br />
                👇👇👇
              </h5>
              <IonRow className="mx-2">
                <IonCol>
                  <div
                    className="hr-border-red-dark"
                    style={{ height: 1.5 }}
                  ></div>
                  <div
                    className="hr-border-yellow-dark"
                    style={{ height: 1.5 }}
                  ></div>
                  <div
                    className="hr-border-green-dark"
                    style={{ height: 1.5 }}
                  ></div>
                  <div
                    className="hr-border-blue-dark"
                    style={{ height: 1.5 }}
                  ></div>
                  <div
                    className="hr-text-dark"
                    style={{ marginTop: -27, width: "90px" }}
                  >
                    <p
                      style={{ fontSize: 13, fontWeight: 600 }}
                      className="m-0"
                    >
                      Jain Shadi Milan
                    </p>
                  </div>
                </IonCol>
              </IonRow>
              {Capacitor.getPlatform() === "android" ? (
                <button
                  onClick={async () => {
                    let mobile = AppContext?.user?.payment_verified_at
                      ? After
                      : Before;
                    await CallNumber.callNumber(String(mobile), true);
                    // await CallNumber.callNumber("7610447511", true);
                  }}
                  className="helpBtn mt-3"
                >
                  {" "}
                  <IonIcon
                    icon={callSharp}
                    color="light"
                    style={{ verticalAlign: "sub", fontSize: "22px" }}
                    className="me-2"
                  />
                  Call Now
                </button>
              ) : (
                <div className="mt-3">
                  <a
                    href={`tel:${
                      AppContext?.user?.payment_verified_at
                        ? String(After)
                        : String(Before)
                    }`}
                    // href={tel:7610447511``}
                    className="helpBtn mt-3"
                    style={{ color: "#fff" }}
                  >
                    {" "}
                    <IonIcon
                      icon={callSharp}
                      color="light"
                      style={{ verticalAlign: "sub", fontSize: "22px" }}
                      className="me-2"
                    />
                    Call Now
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </IonModal>
      {/* ends */}
      <PopUp
        isOpenPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        img={payment}
        goTo={goTo}
        cancel={false}
        componentName={"payment"}
      />
      <React.Suspense fallback={"Loading..."}>
        <Details
          isOpenDetail={isOpenDetail}
          dissmissOpenDetail={() => {
            setOpenDetail(false);
          }}
          id={localStorage.getItem("activeUser")}
        />
      </React.Suspense>
    </div>
  );
};
export default Packages;
