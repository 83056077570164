import React, { useContext, } from 'react';
import appContext from "../../context/AppContext"
import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';


const TopPanel: React.FC = () => {

    const AppContext = useContext(appContext);
    const { i18n } = useTranslation();
    return (
        <>
            <div className='languageDropdown'>
                <IonSelect placeholder="Language"
                    onIonChange={(e) => {
                        i18n.changeLanguage(e.target.value);
                        AppContext.setUserStatus({ language: e.target.value });
                    }}
                    value={AppContext?.userStatus?.language} >
                    <IonSelectOption value="en"  >English</IonSelectOption>
                    <IonSelectOption value="hi">Hindi</IonSelectOption>
                </IonSelect>
            </div>
        </>
    );
};
export default TopPanel;