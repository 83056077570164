import { Redirect, Route, useHistory } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonApp,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonAlert,
  useIonRouter,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import appContext from "./context/AppContext";
import { Network } from "@capacitor/network";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";
import "./theme/common.css";
import "./theme/loader.css";
import "./theme/card.css";
import "./theme/web.css";

import Otp from "./pages/Authentication/Otp";
import Signup from "./pages/Authentication/Signup";
import Auth from "./pages/Authentication/Auth";
import Dashbaord from "./pages/PrivatePage/Dashboard";

//*translation
import "./assets/i18n/config";
// import Profile from "./pages/PrivatePage/Profile";
// import Interest from "./pages/PrivatePage/Interest";
import Userlist from "./pages/Authentication/Userlist";
// import Favourite from "./pages/PrivatePage/Favourite";
// import SecondMarrige from "./pages/PrivatePage/secondMarrige";
// import Details from "./pages/PrivatePage/Details";
import {
  homeOutline,
  heartOutline,
  mailOutline,
  personCircleOutline,
  globeOutline,
  searchOutline,
  lockOpenOutline,
} from "ionicons/icons";
import NotificationModal, {
  MemoizedNotificationModal,
} from "./pages/PrivatePage/NotificationModal";
// import Settings from "./pages/PrivatePage/Settings";
// import SearchModal from "./pages/PrivatePage/SearchModal";
// import ProfileEdit from "./pages/PrivatePage/ProfileEdit";
import { App as CapacitorApp } from "@capacitor/app";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
// import Popups from "./pages/PrivatePage/Popups";
import { Keyboard } from "@capacitor/keyboard";
// import LatestProfile from "./pages/PrivatePage/LatestProfile";
// import UnlockedProfile from "./pages/PrivatePage/UnlockedProfile";
import Test from "./pages/Authentication/test";
import ReferEarn from "./pages/PrivatePage/ReferEarn";
import AppUrlListener from "./AppUrlListener";
import LessDetails from "./pages/PrivatePage/LessDetail";
import constant from "./constant";
import ReactPixel from "react-facebook-pixel";
import { generateToken } from "./service";
import { PushNotifications, Token } from "@capacitor/push-notifications";
// import BlockedUser from "./pages/PrivatePage/BlockedUser";
// import ShadiDoneUsers from "./pages/PrivatePage/ShadiDoneUsers";
// import ProfileView from "./pages/PrivatePage/ProfileView";
import PaymentCallBack from "./pages/PrivatePage/PaymentCallBack";
import { Capacitor } from "@capacitor/core";
const LazySetting = React.lazy(() => import("./pages/PrivatePage/Settings"));
// const LazyDashboard = React.lazy(() => import("./pages/PrivatePage/Dashboard"));
const LazyFavourite = React.lazy(() => import("./pages/PrivatePage/Favourite"));
const LazyProfile = React.lazy(() => import("./pages/PrivatePage/Profile"));
const LazyInterest = React.lazy(() => import("./pages/PrivatePage/Interest"));
const LazyDetails = React.lazy(() => import("./pages/PrivatePage/Details"));
const LazySecondMarrige = React.lazy(
  () => import("./pages/PrivatePage/secondMarrige")
);
const LazySearchModal = React.lazy(
  () => import("./pages/PrivatePage/SearchModal")
);
const LazyProfileEdit = React.lazy(
  () => import("./pages/PrivatePage/ProfileEdit")
);
const LazyPopups = React.lazy(() => import("./pages/PrivatePage/Popups"));
const LazyLatestProfile = React.lazy(
  () => import("./pages/PrivatePage/LatestProfile")
);
const LazyUnlockedProfile = React.lazy(
  () => import("./pages/PrivatePage/UnlockedProfile")
);
const LazyProfileView = React.lazy(
  () => import("./pages/PrivatePage/ProfileView")
);
const LazyShadiDoneUsers = React.lazy(
  () => import("./pages/PrivatePage/ShadiDoneUsers")
);
const LazyBlockedUser = React.lazy(
  () => import("./pages/PrivatePage/BlockedUser")
);

setupIonicReact();

const App = () => {
  const [present] = useIonToast();
  const history = useHistory();
  const [showSearch, SetShowSearch] = useState(true);
  const AppContext = useContext(appContext);
  const [showExitAlert, setShowExitAlert] = React.useState(false);

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) {
        return;
      }

      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.onload = () => {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: "1514829059242805",
            cookie: true,
            xfbml: true,
            version: "v2.4",
          });
        };
      };

      script.onerror = () => {
        console.error("Failed to load Facebook SDK");
      };

      document.body.appendChild(script);
    };

    loadFacebookSDK();
  }, []);

  useEffect(() => {
    const pixelId = "1675808249887591";
    if (Capacitor.getPlatform() == "web") {
      ReactPixel.init(pixelId);
      ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    const checkNetWork = async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        present({
          message: "No Internet Connection",
          duration: 0,
          position: "bottom",
          color: "secondary",
          cssClass: "NetWorkToast",
          animated: true,
          icon: globeOutline,
        });
      }
    };
    Keyboard.addListener("keyboardWillShow", (info) => {
      if (info.keyboardHeight) {
        console.log("sadsa");

        SetShowSearch(false);
      }
    });
    Keyboard.addListener("keyboardWillHide", () => {
      console.log("sadsa");
      SetShowSearch(true);
    });
    checkNetWork();
  }, [present]);

  const handleExit = () => {
    CapacitorApp.exitApp();
  };

  // if (AppContext.user === undefined) {
  if (AppContext.user === undefined) {
    return (
      <>
        {/* <h1 className="onloading">
          <div className="loading-container">
            <div className="loading"></div>
            <div id="loading-text">Loading</div>
          </div>
        </h1> */}

        <div className="onloading">
          <div className="loading-container">
            <img
              src={require("./assets/images/main-logo.png")}
              alt="Loading"
              className="loading-logo"
            />
            <div className="circle circle-large"></div>
          </div>
        </div>
        <IonAlert
          isOpen={showExitAlert}
          onDidDismiss={() => setShowExitAlert(false)}
          cssClass="actionAlert123"
          header={"Confirm Exit"}
          message={"Are you sure you want to exit the app?"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              cssClass: "alertButton",
            },
            {
              text: "Yes",
              handler: handleExit,
              cssClass: "alertButton",
            },
          ]}
        />
      </>
    );
  }

  return (
    <>
      {AppContext.isLoggedIn ? (
        <AppRoutes showSearch={showSearch} />
      ) : (
        <AuthRoutes />
      )}
      <IonAlert
        isOpen={showExitAlert}
        onDidDismiss={() => setShowExitAlert(false)}
        cssClass="actionAlert123"
        header={"Confirm Exit"}
        message={"Are you sure you want to exit the app?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "alertButton",
          },
          {
            text: "Yes",
            handler: handleExit,
            cssClass: "alertButton",
          },
        ]}
      />
    </>
  );
};

const AuthRoutes = () => {
  const mediaQuery = window.matchMedia("(min-width: 576px)");

  useEffect(() => {
    if (window.location.pathname.includes("users")) {
      var id =
        window.location.pathname.split("/")[
          window.location.pathname?.split("/")?.length - 1
        ];
      window.open(`/users${id}`);
    }
  }, [window.location.pathname]);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonRouterOutlet>
          {mediaQuery.matches ? (
            <Route exact path="/" component={Test} />
          ) : (
            <Route exact path="/" component={Auth} />
          )}
          <Route exact path="/signin" component={Auth} />

          <Route exact path="/otp" component={Otp} />
          <Route exact path="/app/users/:id" component={LessDetails} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/user-list" component={Userlist} />
          <Route exact path="/signup-form" component={Signup} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

const AppRoutes = ({ showSearch }: any) => {
  const history = useHistory();
  const mediaQuery = window.matchMedia("(min-width: 576px)");
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const AppContext = useContext(appContext);

  useEffect(() => {
    PushNotifications.register();
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        if (parseInt(notification?.notification?.data?.user_id)) {
          setOpenDetail(true);
          setUserID(parseInt(notification.notification.data.user_id));
        }
      }
    );
  }, []);

  useEffect(() => {
    if (AppContext.isLoggedIn && window.location.pathname === "/signin") {
      window.open(`/app/home`);
      window.location.assign("/app/home");
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("users")) {
      setUserID(
        window.location.pathname.split("/")[
          window.location.pathname?.split("/")?.length - 1
        ]
      );
      setOpenDetail(true);
    }
  }, [window.location.pathname]);

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>
        <IonContent>
          {/* <NotificationModal /> */}
          <MemoizedNotificationModal />
          <React.Suspense fallback="Loading...">
            <LazyPopups />
          </React.Suspense>
          <IonTabs>
            <IonRouterOutlet>
              <Redirect exact path="/" to="/app/home" />
              <Redirect exact path="/app" to="/app/home" />
              <Route exact path="/app/home">
                <Dashbaord />
                {/* <Dashbaord /> */}
              </Route>
              <Route exact path="/app/favourite">
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyFavourite />
                </React.Suspense>
              </Route>
              <Route exact path="/app/interest">
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyInterest />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile">
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyProfile />
                </React.Suspense>
              </Route>
              <Route exact path="/app/unlocked-Profile">
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyUnlockedProfile />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/settings">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  {/* <Settings /> */}
                  <LazySetting />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/profile-edit">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyProfileEdit />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/second-marrige">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazySecondMarrige />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/blocked-user">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyBlockedUser />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/shadi-done-users">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyShadiDoneUsers />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/profile-view">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyProfileView />
                </React.Suspense>
              </Route>
              <Route exact path="/app/profile/refer-earn">
                {" "}
                <ReferEarn />
              </Route>
              <Route exact path="/app/latest-profile">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazyLatestProfile />
                </React.Suspense>
              </Route>
              <Route exact path="/app/search">
                {" "}
                <React.Suspense
                  fallback={
                    <div
                      style={{
                        minHeight: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Loading ...
                    </div>
                  }
                >
                  <LazySearchModal />
                </React.Suspense>
              </Route>
              <Route
                exact
                path="/app/transaction/:transactionId/:status"
                render={(props) => <PaymentCallBack {...props} />}
              />
            </IonRouterOutlet>
            <IonTabBar slot="bottom" className="FooterPanel">
              <IonTabButton tab="home" href="/app/home">
                <IonIcon icon={homeOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Home</IonLabel>
              </IonTabButton>
              <IonTabButton tab="favourite" href="/app/favourite">
                <IonIcon icon={heartOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Favourite</IonLabel>
              </IonTabButton>
              <IonTabButton></IonTabButton>
              <IonTabButton tab="interest" href="/app/interest">
                <IonIcon icon={mailOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Interest</IonLabel>
              </IonTabButton>
              <IonTabButton tab="profile" href="/app/unlocked-Profile">
                <IonIcon icon={lockOpenOutline} className="footerIcon" />
                <IonLabel className="footerLabel">Unlocked</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
          {showSearch && !mediaQuery.matches && (
            <IonFab
              vertical="bottom"
              horizontal="center"
              slot="fixed"
              className="test"
            >
              <IonFabButton routerLink="/app/search">
                <IonIcon icon={searchOutline} className="footerIcon" />
              </IonFabButton>
            </IonFab>
          )}
          <React.Suspense fallback={"Loading..."}>
            <LazyDetails
              isOpenDetail={isOpenDetail}
              dissmissOpenDetail={() => {
                setOpenDetail(false);
                setUserID(null);
              }}
              id={userID}
            />
          </React.Suspense>
        </IonContent>
      </IonReactRouter>
    </IonApp>
  );
};
export default App;
