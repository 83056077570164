import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import Details from "./pages/PrivatePage/Details";
import appContext from "./context/AppContext";

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  const [isOpenDetail, setOpenDetail] = useState<any>(false);
  const [userID, setUserID] = useState<any>("");
  const AppContext = useContext(appContext);

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split(".com").pop();
      if (slug) {
        if (slug.includes("users")) {
          if (AppContext.isLoggedIn) {
            setUserID(slug.split("/")[slug?.split("/")?.length - 1]);
            setOpenDetail(true);
          } else {
            history.push(slug);
          }
        } else {
          history.push(slug);
        }
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return (
    <Details
      isOpenDetail={isOpenDetail}
      dissmissOpenDetail={() => {
        setOpenDetail(false);
        setUserID(null);
      }}
      id={userID}
    />
  );
};

export default AppUrlListener;
