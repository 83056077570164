import {
  IonBadge,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonImg,
  IonPage,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonTabBar,
  IonTabButton,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonIcon } from "@ionic/react";
import { IonInput, IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  arrowBackOutline,
  call,
  checkmarkDoneOutline,
  copyOutline,
  notificationsOutline,
  playCircle,
  shareSharp,
  shareSocialSharp,
  thumbsDownSharp,
  thumbsUpSharp,
} from "ionicons/icons";
import { getPendingHistory, getSuccessHistory, signUp } from "../../service";
import refer from "../../assets/images/refer.jpeg";
import { Clipboard } from "@capacitor/clipboard";
// import { Share } from '@capacitor/share';
import moment from "moment";

const ReferEarn: React.FC = () => {
  const AppContext = useContext(appContext);
  const history = useHistory();
  const [present] = useIonToast();
  const [activeTab, setActiveTab] = useState("pending");
  const [loading, setLoading] = useState(true);
  const [pendingData, setPendingData] = useState([]);
  const [successData, setSuccessData] = useState([]);

  useEffect(() => {
    getPendingData();
    getSuccessData();
  }, []);

  const getPendingData = async () => {
    var response = await getPendingHistory();
    setLoading(false);
    setPendingData(response?.data?.data);
  };

  const getSuccessData = async () => {
    var response = await getSuccessHistory();
    setLoading(false);
    setSuccessData(response?.data?.data);
  };

  const writeToClipboard = async () => {
    await Clipboard.write({
      string: `${AppContext?.user?.referral_code}`,
    });
    present({
      message: "Copy Referral Code Successfully.",
      duration: 1000,
      position: "top",
      color: "secondary",
      animated: true,
    });
  };

  const checkClipboard = async () => {
    const { type, value } = await Clipboard.read();
  };

  return (
    <IonPage>
      <div style={{ height: "100vh" }} className="profileBody">
        <IonContent>
          <div
            className="topCard ion-text-left pb-2"
            style={{ borderRadius: "0px", height: "auto" }}
          >
            <IonButton
              className="outlineBtn"
              onClick={() => {
                history.goBack();
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
            <div className="text-center">
              <div className="referImg mt-2">
                <img loading="lazy" src={refer}></img>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "20px 0",
                }}
              >
                <div>
                  <p className="fs-14  mb-2" style={{ color: "#fff" }}>
                    <b>Total Earnings</b>
                  </p>
                  <h5
                    className="light mb-1 font-weight-700"
                    color="light"
                    style={{ textTransform: "capitalize" }}
                  >
                    ₹ {AppContext?.user?.amount}.00{" "}
                  </h5>
                </div>
                <div>
                  <p className="fs-14 mb-2" style={{ color: "#fff" }}>
                    <b>Available Balance</b>
                  </p>
                  <h5
                    className="light mb-1 font-weight-700"
                    color="light"
                    style={{ textTransform: "capitalize" }}
                  >
                    ₹ {AppContext?.user?.balance}.00{" "}
                  </h5>
                </div>
              </div>

              <div
                className="my-3 px-2 mt-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="referSec">
                  <IonInput
                    className="referInput"
                    value={AppContext?.user?.referral_code}
                    readonly={true}
                  ></IonInput>
                  <IonButton
                    type="button"
                    color={"primary"}
                    fill="clear"
                    shape="round"
                    onClick={(event) => {
                      event.stopPropagation();
                      writeToClipboard();
                      checkClipboard();
                    }}
                  >
                    <IonIcon
                      icon={copyOutline}
                      style={{ color: "#797F85" }}
                      className="fs-24"
                    />
                  </IonButton>
                </div>
                <div className="shareBtn">
                  <IonButton
                    style={{ height: 40 }}
                    onClick={async () => {
                      // await Share.share({
                      //     title: 'Your JSM Referral Code',
                      //     text: `🔊📙 जैन शादी मिलन📙🔊 \n 🙏 जय जिनेन्द्र बंधुओं 🙏 \n आपको यह जानकर अत्यंत हर्ष होगा कि सकल जैन समाज के विवाह योग्य युवक-युवतियों के योग्य सम्बन्ध खोजने के लिए जैन ऑनलाइन पत्रिका की स्थापना की गई है। \n:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:-:
                      //     \n 🔔 रजिस्ट्रेशन के लिए 🔔
                      //     \n1. नीचे दी गई लिंक से 📲 WhatsApp (7610447511) परजय जिनेन्द्रलिखकर भेजे | -
                      //     \n https://wa.me/message/E4RFRCCIPWM7L1
                      //     \n📲 Android App link - नीचे दी गई लिंक से एप्प को डाउनलोड करें 👇
                      //     \nhttps://play.google.com/store/apps/details?id=com.jsm.digital
                      //     \nNote - IOS(iPhone) मोबाइल में वेबसाइट का प्रयोग करें !👇
                      //     \n🌐 Website Registration link-
                      //     \n https://mobile.jainshadimilan.com/
                      //     \n✨✨✨✨✨
                      //     \n📢लिंक पर क्लिक नही होता है तो मोबाईल नम्बर को "Jain Shadi Milan" नाम से save करें या Reply में "jaijinendra" लिखकर भेजे।
                      //     \n- जैन शादी मिलन
                      //     \n(जैन युवक-युवती परिचय एप्प)
                      //     \n 👉 7610447511📢
                      //     \n👉 7610447512📢
                      //     \n 📲 इस मैसेज को सभी जैनों को शेयर करे।
                      //     \n Note : *शुल्क में छूट (Discount) के लिए रिफ्रेल (Refferal) कोड {${AppContext?.user?.referral_code}} का उपयोग करे*। `,
                      //     dialogTitle: 'Share with buddies',
                      //     files: [""],
                      // });
                    }}
                  >
                    <IonIcon icon={shareSocialSharp} className="me-2" />
                    Share
                  </IonButton>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomCard  p-0" style={{ borderRadius: "0px" }}>
            <IonTabBar slot="bottom">
              <IonTabButton
                tab="pending"
                onClick={() => {
                  setActiveTab("pending");
                }}
                style={
                  activeTab === "pending"
                    ? { borderBottom: "1px solid red" }
                    : { borderBottom: "none" }
                }
              >
                <IonLabel>Pending({pendingData?.length})</IonLabel>
              </IonTabButton>
              <IonTabButton
                tab="success"
                onClick={() => {
                  setActiveTab("success");
                }}
                style={
                  activeTab === "success"
                    ? { borderBottom: "1px solid red" }
                    : { borderBottom: "none" }
                }
              >
                <IonLabel>Success({successData?.length})</IonLabel>
              </IonTabButton>
            </IonTabBar>
            <div className="my-3 p-2">
              {loading ? (
                [...Array(3)].map((i) => (
                  <IonItem className="px-2">
                    <IonLabel
                      className="text-muted  font-weight-700"
                      style={{ fontSize: 14 }}
                    >
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "60%" }}
                        ></IonSkeletonText>
                      </p>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "50%" }}
                        ></IonSkeletonText>
                      </p>
                    </IonLabel>
                    <IonThumbnail slot="end">
                      <IonSkeletonText
                        animated={true}
                        style={{ borderRadius: 5, width: "50%", height: "50%" }}
                      ></IonSkeletonText>
                    </IonThumbnail>
                  </IonItem>
                ))
              ) : (
                <>
                  {activeTab === "success" ? (
                    <>
                      {successData.length === 0 ? (
                        <p className=" px-2 ion-text-center">
                          No Records Found
                        </p>
                      ) : (
                        <>
                          {successData?.map((i: any) => {
                            return (
                              <IonItem className="px-2">
                                <IonLabel
                                  className="text-muted  font-weight-700"
                                  style={{ fontSize: 14 }}
                                >
                                  <h3>{i?.name}</h3>
                                  <p>
                                    {moment(i?.created_at).format(
                                      "DD-MM-YYYY  , hh:mm a"
                                    )}
                                  </p>
                                </IonLabel>
                                <IonBadge slot="end" color="primary">
                                  ₹ {i?.amount}
                                </IonBadge>
                              </IonItem>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {pendingData.length === 0 ? (
                        <p className=" px-2 ion-text-center">
                          No Records Found
                        </p>
                      ) : (
                        <>
                          {pendingData?.map((i: any) => {
                            return (
                              <IonItem className="px-2">
                                <IonLabel
                                  className="text-muted  font-weight-700"
                                  style={{ fontSize: 14 }}
                                >
                                  <h3>{i?.nameEnglish}</h3>
                                  <p>
                                    {moment(i?.created_at).format(
                                      "DD-MM-YYYY  , hh:mm a"
                                    )}
                                  </p>
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </IonContent>
      </div>
      {/* <IonModal isOpen={isAppUpdating}>
                <IonContent>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '28vh',
                        position: 'absolute',
                        width: '100%',
                        paddingLeft: '5vw',
                        paddingRight: '5vw',
                        textAlign: 'center'
                    }}>
                        <h2>hold on!</h2>
                        <h6>We are updating you<br />
                            The latest app of <b style={{ color: '#EF423B' }}>Jain Shadi Milan</b></h6>
                    </div>
                    <h1 className='onloading'>
                        <div className="loading-container">
                            <div className="loading"></div>
                            <div id="loading-text">UPDATING</div>
                        </div>
                    </h1>
                </IonContent>
            </IonModal> */}
    </IonPage>
  );
};
export default ReferEarn;
