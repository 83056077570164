import {
  IonBadge,
  IonHeader,
  IonImg,
  IonLabel,
  IonNav,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import constant from "../../constant";
import appContext from "../../context/AppContext";
import {
  notifications,
  notificationsOutline,
  personCircleOutline,
  searchOutline,
} from "ionicons/icons";
// new import files
import "../../theme/web.css";
import {
  getAllNotification,
  getPushNotification,
  getPushNotification1,
} from "../../service";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  personOutline,
  heartOutline,
  homeOutline,
  mailOutline,
  search,
} from "ionicons/icons";
import logo from "../../assets/images/logo.png";
import Dashbaord from "../PrivatePage/Dashboard";
import namaste from "../../assets/images/namaste icon.png";
import TopPanel from "./TopPanel";
import language from "../../assets/images/language.png";
import Notification from "../../assets/images/Notification.png";
import threelinebar from "../../assets/images/threelinebar.png";

const Header = () => {
  const AppContext = useContext(appContext);
  var link = window.location.pathname;
  const [image, setImage] = useState<any>(null);
  const [routeName, setRouteName] = useState<any>(
    link?.split("/")[link?.split("/")?.length - 1]
  );
  const [currentTab, setCurrentTab] = useState<any>(window.location.pathname);
  const history = useHistory();
  const [showLanguage, setShowLanguage] = useState<any>(false);
  const [pushNoti, setPushNoti] = useState<any>(0);

  useEffect(() => {
    if (AppContext.user && AppContext.user?.profiles?.length) {
      let p = AppContext?.user?.profiles?.find((i: any) => {
        return i?.type === "profile" && i?.order_number === 10;
      });
      if (!p)
        p = AppContext?.user?.profiles?.find((i: any) => {
          return i?.type === "profile";
        });

      if (p) setImage(`${constant.ImageS3URl}/${p?.folder}/${p?.name}`);
    }
  }, [AppContext.user]);

  const getNotificationPush = async () => {
    let res0: any = await getAllNotification(0);
    setPushNoti(res0?.data?.data?.count);
  };

  useEffect(() => {
    getNotificationPush();
  }, []);

  useEffect(() => {
    setShowLanguage(false);
  }, [AppContext.userStatus]);
  useEffect(() => {
    setCurrentTab(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <IonHeader className="header-top w-100" color="primary">
        {/* <IonHeader className='px-1 ' > */}
        <IonToolbar color="primary">
          <IonThumbnail slot="start">
            <div className="ps-1 profileMainBody">
              <NavLink to="/app/profile">
                <img
                  src={
                    image
                      ? image
                      : "https://ionicframework.com/docs/img/demos/avatar.svg"
                  }
                  style={{
                    border: "3px solid",
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="rounded-4 border-1 border-light avatar-xl img-profile user-profile-image shadow"
                  alt="abc"
                />
              </NavLink>
            </div>
            <img
              style={{
                position: "absolute",
                background: "white",
                height: "17px",
                width: "17px",
                top: "60%",
                left: "9%",
                borderRadius: "5px",
              }}
              src={threelinebar}
            />
          </IonThumbnail>
          <p
            className="userName m-0 ms-1 px-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <b>Jai Jinendra </b>
            <img loading="lazy" src={namaste} style={{ width: 35 }} />
            {/* <b><span className='color-primary' style={{ textTransform: "capitalize" }} >
              , {AppContext?.user?.nameEnglish?.split(" ")[0]} Ji</span></b> */}
          </p>
          {/* <div slot="end" className='backBtn'><TopPanel /></div> */}
          <div slot="end" className="backBtn">
            {/* <IonButton className="px-1" style={{ height: "45px", width: "45px" }} onClick={() => setShowLanguage(true)}>
              <img alt='Language' src={language} />
            </IonButton> */}
            <IonButton
              className="px-1"
              style={{ height: "47px", width: "47px" }}
              onClick={() => {
                AppContext.setNotification(true);
              }}
            >
              {/* <IonIcon icon={Notification} style={{ color: "#fff" }} /> */}
              <img alt="Language" src={Notification} />
              <p
                style={{
                  position: "absolute",
                  background: "white",
                  color: "#ef423b",
                  padding: "2px",
                  borderRadius: "50%",
                  right: "2%",
                  top: "-49%",
                  fontSize: "10px",
                  height: "12px",
                }}
              >
                {pushNoti}
              </p>
            </IonButton>
            {/* <IonBadge style={{position: absolute}} color={"light"} slot="end">0</IonBadge> */}
          </div>
        </IonToolbar>
      </IonHeader>

      {showLanguage && <TopPanel />}

      {/* new added section in header bar */}
      <div className="tab-bar-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img
                loading="lazy"
                src={logo}
                alt="logo-image"
                style={{ width: "8rem" }}
              ></img>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  Jai Jinendra 🙏
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      to="/app/home"
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={homeOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        Home
                      </IonLabel>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app/favourite"
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={heartOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        Favourite
                      </IonLabel>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app/search"
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={searchOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        Search
                      </IonLabel>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app/interest"
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={mailOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        Interest
                      </IonLabel>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app/profile"
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={personCircleOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        {" "}
                        Profile
                      </IonLabel>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/app/unlocked-Profile"
                      onClick={() => {
                        // setRouteName("/app/unlocked-Profile");
                        // setwindow.location.pathname("/app/unlocked-Profile");
                      }}
                      className={(isActive: any) => {
                        return isActive
                          ? "nav-link color-primary active"
                          : "nav-link";
                      }}
                    >
                      <IonIcon
                        className="fs-22"
                        icon={personCircleOutline}
                        style={{ verticalAlign: "sub" }}
                      />
                      <IonLabel className="ms-2 header-item-text">
                        Unlocked Profile
                      </IonLabel>
                    </NavLink>
                  </li>
                </ul>

                <form className="d-flex ms-auto">
                  <IonButton
                    className="px-1"
                    onClick={() => {
                      AppContext.setNotification(true);
                    }}
                  >
                    <IonIcon
                      icon={notificationsOutline}
                      style={{ color: "#fff" }}
                    />
                  </IonButton>
                </form>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
