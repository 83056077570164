import { IonIcon, IonImg, IonPage, useIonToast } from "@ionic/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { IonItem, IonLabel } from "@ionic/react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { IonInput, IonList, IonButton } from "@ionic/react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SendOtp, forgetPassword, updateUserPassword } from "../../service";
import { eyeOffSharp, eyeSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { AxiosError } from "axios";

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const historyObj = useMemo(() => {
    return history;
  }, [history]);
  const { state }: any = useLocation();
  const [paswdError, setPaswdError] = useState(false);
  const [showPassword, setShowPassword] = useState<any>(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (
      localStorage.getItem(constant.otpRestore) &&
      localStorage.getItem(constant.otpRestore) !== "undefined" &&
      localStorage.getItem(constant.otpRestore) !== "null"
    ) {
      historyObj.push({
        pathname: constant.routes.otp,
        state: { mobile: localStorage.getItem(constant.otpRestore) },
      });
    }
    if (state?.mobile) {
      setValue("mobile", state?.mobile);
    }
  }, [historyObj, state, setValue]);

  const [updatePassword, setUpdatePassword] = useState<any>(false);
  const [present] = useIonToast();

  const onSubmit = async (data: any) => {
    console.log("data", data);

    if (updatePassword) {
      if (data.password === data.cnfmpassword) {
        setPaswdError(false);
        AppContext.setLoading(true);
        var response = await updateUserPassword(data);
        AppContext.setLoading(false);
        console.log("response", response);
        if (response?.data?.status) {
          present({
            message: response?.data?.message,
            duration: 1500,
            position: "top",
            color: "success",
            cssClass: "alertToast",
          });
          history.push("/signin");
        } else {
          present({
            message: response?.data?.message,
            duration: 1500,
            position: "top",
            color: "primary",
            cssClass: "alertToast",
          });
        }
      } else {
        setPaswdError(true);
      }
    } else {
      AppContext.setLoading(true);
      var res = await forgetPassword(data);
      if (res?.data?.status) {
        setUpdatePassword(true);
        AppContext.setLoading(false);
      }
    }
  };

  const reSendOtp = async () => {
    const mob = getValues("mobile");
    try {
      var obj = { mobile: mob };
      let response = await SendOtp(obj);
      if (response?.data?.status) {
        present({
          message: "OTP send Successfully",
          duration: 1500,
          position: "top",
          color: "success",
          cssClass: "alertToast",
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      present({
        message: (axiosError?.response?.data as any)?.message,
        duration: 1500,
        position: "top",
        color: "danger",
      });
    }
  };

  const AppContext = useContext(appContext);
  const mediaQuery = window.matchMedia("(min-width: 576px)");

  return (
    <IonPage className="bg">
      <div
        className={
          mediaQuery.matches ? "test-background-image" : "bg UserListBody"
        }
      >
        <div className={mediaQuery.matches ? "auth-content" : ""}>
          <div>
            <img
              loading="lazy"
              className="web-logo"
              src={logo}
              alt="logo-image"
            ></img>
            <div style={{ marginTop: "20px" }} className="web-body-text ">
              <IonLabel>
                <h1 style={{ width: 500 }}>
                  <b>
                    Best Matrimony Site Used By Millions of Jainism Members .
                  </b>
                </h1>
              </IonLabel>
            </div>
          </div>
          <div className="authbottomCover auth-web-bottom">
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonRow class="ion-justify-content-center bottom-text">
                <IonCol>
                  <div className="otp-lock"></div>
                </IonCol>
                <IonCol size="11">
                  <h5 style={{ lineHeight: "24px" }}>
                    <b>
                      {updatePassword
                        ? t("Auth.UpdatePassword")
                        : t("Auth.ForgotPassword")}
                    </b>
                  </h5>
                  <p className="m-0" style={{ fontSize: 13, color: "grey" }}>
                    {updatePassword
                      ? t("Auth.UpdateHeadingText")
                      : t("Auth.ForgetHeadingText")}
                  </p>
                </IonCol>
              </IonRow>

              <div className="loginForm dasktop-signup ">
                <IonList className="p-1">
                  <IonItem>
                    <IonLabel position="stacked" className="label">
                      {t("Auth.mobile")}
                    </IonLabel>
                    <div className="languageDropdown">
                      <IonInput
                        value="+ 91"
                        disabled
                        style={{
                          maxWidth: "60px",
                          marginRight: "15px",
                          borderBottom: "0.5px solid #989aa2",
                          lineHeight: "35px",
                        }}
                      ></IonInput>
                      <IonInput
                        type="number"
                        pattern="[0-9]*"
                        style={{
                          width: "500px",
                          borderBottom: "0.5px solid #989aa2",
                          lineHeight: "35px",
                        }}
                        maxlength={10}
                        {...register("mobile", {
                          required: true,
                          maxLength: 10,
                          minLength: 10,
                        })}
                      ></IonInput>
                    </div>
                  </IonItem>
                  {errors.mobile && errors.mobile.type === "required" && (
                    <p className="errorText mb-0">
                      {t("AuthError.MobileRequire")}
                    </p>
                  )}
                  {errors.mobile && errors.mobile.type === "maxLength" && (
                    <p className="errorText mb-0">
                      Mobile Number must have 10 digits
                    </p>
                  )}
                  {errors.mobile && errors.mobile.type === "minLength" && (
                    <p className="errorText mb-0">
                      Mobile Number must have 10 digits
                    </p>
                  )}

                  {updatePassword && (
                    <>
                      <IonItem>
                        <IonLabel position="stacked" className="label">
                          OTP
                        </IonLabel>
                        <div className="languageDropdown">
                          <IonInput
                            type="number"
                            style={{
                              width: "500px",
                              borderBottom: "0.5px solid #989aa2",
                              lineHeight: "35px",
                            }}
                            {...register("otp", { required: true })}
                          ></IonInput>
                        </div>
                      </IonItem>
                      {errors.password &&
                        errors.password.type === "required" && (
                          <p className="errorText mb-0">OTP is required</p>
                        )}
                      <IonItem>
                        <IonButton
                          slot={"start"}
                          onClick={reSendOtp}
                          fill={"clear"}
                        >
                          Resend Otp
                        </IonButton>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked" className="label">
                          Password
                        </IonLabel>
                        <div className="languageDropdown">
                          <IonInput
                            type={showPassword ? "text" : "password"}
                            style={{
                              width: "500px",
                              borderBottom: "0.5px solid #989aa2",
                              lineHeight: "35px",
                              position: "relative",
                            }}
                            {...register("password", {
                              required: true,
                              minLength: 6,
                            })}
                          >
                            {!showPassword ? (
                              <IonButton
                                color={"primary"}
                                fill="clear"
                                shape="round"
                                onClick={() => {
                                  setShowPassword(true);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  fontSize: "18px",
                                }}
                              >
                                <IonIcon
                                  icon={eyeSharp}
                                  style={{ color: "#797F85" }}
                                />
                              </IonButton>
                            ) : (
                              <IonButton
                                fill="clear"
                                color={"primary"}
                                shape="round"
                                onClick={() => {
                                  setShowPassword(false);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  fontSize: "18px",
                                }}
                              >
                                <IonIcon
                                  icon={eyeOffSharp}
                                  style={{ color: "#797F85" }}
                                />
                              </IonButton>
                            )}
                          </IonInput>
                        </div>
                      </IonItem>
                      {errors.password &&
                        errors.password.type === "required" && (
                          <p className="errorText mb-0">Password is required</p>
                        )}
                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <p className="errorText mb-0">
                            Password must have 6 digits
                          </p>
                        )}

                      <IonItem>
                        <IonLabel position="stacked" className="label">
                          Confirm Password
                        </IonLabel>
                        <div className="languageDropdown">
                          <IonInput
                            type={showPassword ? "text" : "password"}
                            style={{
                              width: "500px",
                              borderBottom: "0.5px solid #989aa2",
                              lineHeight: "35px",
                              position: "relative",
                            }}
                            {...register("cnfmpassword", {
                              required: true,
                              minLength: 6,
                            })}
                          >
                            {!showPassword ? (
                              <IonButton
                                color={"primary"}
                                fill="clear"
                                shape="round"
                                onClick={() => {
                                  setShowPassword(true);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  fontSize: "18px",
                                }}
                              >
                                <IonIcon
                                  icon={eyeSharp}
                                  style={{ color: "#797F85" }}
                                />
                              </IonButton>
                            ) : (
                              <IonButton
                                fill="clear"
                                color={"primary"}
                                shape="round"
                                onClick={() => {
                                  setShowPassword(false);
                                }}
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  fontSize: "18px",
                                }}
                              >
                                <IonIcon
                                  icon={eyeOffSharp}
                                  style={{ color: "#797F85" }}
                                />
                              </IonButton>
                            )}
                          </IonInput>
                        </div>
                      </IonItem>
                      {errors.cnfmpassword &&
                        errors.cnfmpassword.type === "required" && (
                          <p className="errorText mb-0">
                            Confirm Password is required
                          </p>
                        )}
                      {errors.cnfmpassword &&
                        errors.cnfmpassword.type === "minLength" && (
                          <p className="errorText mb-0">
                            Confirm Password must have 6 digits
                          </p>
                        )}
                      {paswdError && (
                        <p className="errorText mb-0">
                          Password and confirm password must be same.
                        </p>
                      )}
                    </>
                  )}

                  <IonGrid>
                    {updatePassword ? (
                      <IonRow>
                        <IonCol class="btnFill  ion-margin-bottom">
                          <IonButton type="submit" expand="block">
                            Update
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    ) : (
                      <IonRow>
                        <IonCol class="btnFill  ion-margin-bottom">
                          <IonButton type="submit" expand="block">
                            Send
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    )}
                    <IonRow>
                      <IonCol>
                        <div className="hr-border-red"></div>
                        <div className="hr-border-yellow"></div>
                        <div className="hr-border-green"></div>
                        <div className="hr-border-blue"></div>
                        <div className="hr-text">OR</div>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-text-center">
                      <IonCol class="btnOutline">
                        <IonButton
                          type={"button"}
                          onClick={() => {
                            history.push("/signin");
                          }}
                        >
                          Back to login
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonList>
              </div>
            </form>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default ForgetPassword;
