import { Controller } from "react-hook-form";
import Select from 'react-select'


const CustomSelect = ({ options, control, name, required, placeholder, isSearchable = true, onChangeMethod }: any) => (
    <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (<Select maxMenuHeight={280}
            blurInputOnSelect={false}
            isSearchable={isSearchable}
            placeholder={<div>{placeholder || "Select"}</div>}
            className='border-bottom'
            theme={(theme) => ({
                ...theme,
                spacing: {
                    ...theme.spacing,
                    controlHeight: 30,
                },
                borderRadius: 5,
                colors: {
                    ...theme.colors,
                    primary25: '#fff0f1',
                    primary: '#fff0f1',
                    neutral50: '#b27878'
                },
            })}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: state.isFocused ? '#fff0f1' : 'white',
                    fontWeight: 600,
                    fontSize: 15,
                    height: 40,
                }),
            }}
            options={options}
            {...field}
            value={options?.filter((option: any) => option?.label === (field?.value?.value || field?.value))}
        />)}
    />
)
export default CustomSelect;