import {
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonIcon,
  IonButton,
  IonCard,
  IonCardHeader,
  IonItem,
  IonImg,
  useIonAlert,
  IonThumbnail,
  IonPopover,
  IonContent,
  IonAlert,
  useIonToast,
  IonList,
  IonModal,
} from "@ionic/react";
import {
  bagHandleSharp,
  cameraSharp,
  heart,
  locationSharp,
  nuclearSharp,
  send,
  shieldCheckmarkSharp,
  starHalfSharp,
  starSharp,
} from "ionicons/icons";
import moment from "moment";
import { useContext, useState } from "react";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import Utils from "../utils";
import PopUp from "./PopUp";
import publish from "../../assets/images/publish.png";
import {
  deleteInterestRequest,
  blockedUser,
  shadiDoneUser,
  reportUser,
} from "../../service";
import { toLower } from "lodash";

const styles: any = {
  disabledGrid: {
    filter: "blur(1px)",
    pointerEvents: "none",
  },
  blurOverlay: {
    position: "absolute",
    opacity: "1 !important",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "999",
    backdropFilter: "blur(1px)",
  },
  nowAvailableText: {
    position: "absolute",
    top: "41%",
    left: "50%",
    zIndex: "999",
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
  nowAvailableSubText: {
    position: "absolute",
    width: "100%",
    top: "57%",
    left: "50%",
    zIndex: "999",
    fontSize: "1em",
    fontWeight: "bold",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    color: "red",
  },
};

const NewUserCard = ({
  addToFavorite,
  sendInterest,
  user,
  i,
  history,
  sendPhotoRequest,
  isOpenDetailModal,
  onAction,
}: any) => {
  const AppContext = useContext(appContext);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [presentAlert] = useIonAlert();
  const [showPopover, setShowPopover] = useState(false);
  const [event, setEvent] = useState<any>();
  const [present] = useIonToast();
  const CurrentPackageBasic = AppContext?.currentPackages?.CurrentPackageBasic;
  const CurrentPackageSpecial =
    AppContext?.currentPackages?.CurrentPackageSpecial;
  const CurrentPackageVip = AppContext?.currentPackages?.CurrentPackageVip;

  let p = user?.profiles?.find((i: any) => {
    return i?.type === "profile" && i?.order_number === 10;
  });
  if (!p)
    p = user?.profiles?.find((i: any) => {
      return i?.type === "profile";
    });

  const handleRemoveInterest = async () => {
    const res = await deleteInterestRequest(user);
    if (res?.data?.status) {
      user.interest = false;
      present({
        message: "Sent Interest Cancelled",
        duration: 1500,
        position: "top",
        color: "success",
        cssClass: "alertToast",
      });
      setShowAlert(false);
    }
  };

  const goTo = async () => {
    setOpenPopUp(false);
    AppContext.setPopups(constant.popups.isGoLive, true);
  };

  const calculateAge = (dob: any) => {
    const now = moment();
    const birthDate = moment(dob);
    const age = now.diff(birthDate, "years");
    const nextBirthday = birthDate.add(age, "years");
    const hasHadBirthdayThisYear = now.isSameOrAfter(nextBirthday);

    return hasHadBirthdayThisYear ? age : age - 1;
  };

  return (
    <div id="NewUserCard">
      <IonCard
        key={i}
        onClick={() => {
          if (
            !user?.blocked &&
            !user?.shadiDone &&
            !user?.blockedUserIdByMe &&
            !user?.shadiDoneByMe
          ) {
            isOpenDetailModal(true);
            onAction(user?.id);
          }
        }}
        className="box-new-profile ion-justify-content-center ion-align-items-center"
      >
        {(user?.blocked ||
          user?.shadiDone ||
          user?.blockedUserIdByMe ||
          user?.shadiDoneByMe) && <div style={styles.blurOverlay}></div>}
        <IonGrid
          className="ion-margin-0"
          style={{
            maxHeight: "16em",
            margin: 0,
            opacity: `${
              user?.blocked ||
              user?.shadiDone ||
              user?.blockedUserIdByMe ||
              user?.shadiDoneByMe
                ? "0.5"
                : "1"
            }`,
          }}
        >
          <IonRow>
            <IonCol
              className="image-newCard"
              style={{
                backgroundImage:
                  !p?.name &&
                  Utils.getDefaultUserUrl(
                    AppContext?.user?.first_formGenderEnglish
                  ),
                backgroundSize: p?.name ? "100% 100%" : "90px",
              }}
              sizeXs="5"
              sizeSm="5"
              sizeLg="5"
            >
              {/* {p?.name ? ( */}
              <IonImg
                src={
                  p?.name
                    ? `${constant.ImageS3URl}/${p?.folder}/${p?.name}`
                    : "https://ionicframework.com/docs/img/demos/avatar.svg"
                }
                className="CardImage"
                style={{
                  height: "10.3em",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              ></IonImg>

              {/* ) : null} */}
              {user?.verified_at ? (
                <div className="corner-ribbon-new">
                  <span className="cr-inner-new">
                    <span className="cr-text-new">
                      {" "}
                      <IonIcon
                        style={{ fontSize: "2em" }}
                        icon={shieldCheckmarkSharp}
                      />
                      <br />
                      Verified
                    </span>
                  </span>
                </div>
              ) : null}
            </IonCol>
            <IonCol className="ps-2 pb-0 mb-0" sizeSm="7" sizeXs="7" sizeLg="7">
              <IonRow className="ps-1">
                <IonLabel style={{ margin: 0, width: "100%" }}>
                  <p
                    className="color-primary font-weight-500 m-0"
                    style={{ fontSize: 12, lineHeight: "12px" }}
                  >
                    <b>JSM-{user?.id}</b>
                  </p>
                  <div className="my-1 userCardNameandAge">
                    <b className="ellispses" style={{ width: "55%" }}>
                      {user?.nameEnglish?.charAt(0)?.toUpperCase() +
                        user?.nameEnglish?.slice(1)}
                    </b>

                    <b style={{ fontSize: "12px" }} className="cardAge">
                      &nbsp;(
                      {user?.dob ? calculateAge(user?.dob) : user?._age} Yrs)
                    </b>
                  </div>
                  <p className="text-muted subInfoUserCard">
                    <b>
                      {user.third_formSectEnglish}{" "}
                      {user.third_formSectEnglish ? <span>|</span> : ""}{" "}
                      {user.first_formMaritalStatusEnglish}{" "}
                      {user.first_formMaritalStatusEnglish ? (
                        <span>|</span>
                      ) : (
                        ""
                      )}
                      <br />{" "}
                      {user.fourth_formEmployedEnglish
                        ? user.fourth_formEmployedEnglish
                        : "-"}{" "}
                    </b>
                  </p>
                </IonLabel>
              </IonRow>
              <IonRow className="p-0 m-0">
                <div className="p-0 m-0 manglikAndAddressUserCard">
                  {/* <div style={{ flex: "100%" }}> */}
                  <IonLabel style={{ margin: 0 }}>
                    <p className="m-0 manglikUserCard">
                      <IonIcon
                        icon={starSharp}
                        color="primary"
                        style={{ verticalAlign: "sub" }}
                      />{" "}
                      Manglikta
                    </p>
                    <p className="manglikValueUserCard">
                      <b>{user.third_formDoshEnglish || "-"}</b>
                    </p>
                  </IonLabel>
                  {/* </div> */}
                  <div
                    className="block-ad-md"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20px",
                      padding: "0px 6px",
                      flex: "5%",
                    }}
                  >
                    <div
                      style={{ width: 1, height: 29, background: "#797f85" }}
                    ></div>
                  </div>
                  <div style={{ flex: "100%", maxWidth: "80px" }}>
                    <IonLabel style={{ margin: 0 }}>
                      <p className="m-0 addressUserCard">
                        <IonIcon
                          icon={locationSharp}
                          color="primary"
                          style={{ verticalAlign: "sub" }}
                        />{" "}
                        Address
                      </p>
                      <p className="addressValueUserCard">
                        <b>{user.second_formStateEnglish || "-"}</b>
                      </p>
                    </IonLabel>
                  </div>
                </div>
              </IonRow>
              <IonRow className="mt-1">
                <IonCol
                  sizeSm="12"
                  sizeXs="7"
                  sizeLg="7"
                  className="ion-no-padding ion-no-margin sendInterestBtn"
                >
                  <IonButton
                    style={{ fontSize: "x-small", width: "98%" }}
                    color={"primary"}
                    size="small"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      if (!AppContext?.user?.second_formMobile)
                        AppContext.setNoDelayPopup(constant.popups.isGoLive);
                      else if (
                        CurrentPackageBasic === null &&
                        CurrentPackageVip === null &&
                        CurrentPackageSpecial !== null
                      )
                        AppContext.setNoDelayPopup(constant.popups.isSubscribe);
                      else if (!AppContext?.user?.payment_verified_at)
                        AppContext.setNoDelayPopup(constant.popups.isSubscribe);
                      else if (!user?.interest) sendInterest(user, i);
                      else if (user?.interest) {
                        setShowAlert(true);
                      }
                    }}
                  >
                    <IonIcon className="white-New" icon={send} />{" "}
                    {!user?.interest ? "Send Interest" : "Cancel"}
                  </IonButton>
                </IonCol>
                <IonCol
                  sizeSm="12"
                  sizeXs="5"
                  sizeLg="5"
                  className="favouriteBtn ion-no-padding ion-no-margin"
                >
                  <IonButton
                    style={{ fontSize: "x-small", width: "94%" }}
                    color={user?.favorite ? "secondary" : "warning"}
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      addToFavorite(user, i);
                    }}
                  >
                    <IonIcon className="favouriteBtnIcon" icon={heart} />{" "}
                    Favorite
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
        {user?.blocked &&
          !user?.shadiDone &&
          !user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                Sorry this person has blocked you!
              </p>
            </>
          )}
        {user?.shadiDone &&
          !user?.blocked &&
          !user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                Sorry this person moved you in shadi done list !
              </p>
            </>
          )}
        {!user?.shadiDone &&
          !user?.blocked &&
          user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>You blocked this person!</p>
            </>
          )}
        {!user?.shadiDone &&
          !user?.blocked &&
          !user?.blockedUserIdByMe &&
          user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                You moved this person to Shadi Done list!
              </p>
            </>
          )}
        {user?.shadiDone &&
          user?.blocked &&
          !user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                Sorry this person moved you in shadi done list & blocked you !
              </p>
            </>
          )}
        {!user?.shadiDone &&
          !user?.blocked &&
          user?.blockedUserIdByMe &&
          user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                You moved this person in shadi done list & blocked him !
              </p>
            </>
          )}
        {user?.shadiDone &&
          !user?.blocked &&
          user?.blockedUserIdByMe &&
          user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                You moved this person in shadi done list, blocked list!
              </p>
              <p style={styles.nowAvailableSubText}>
                You this person moved you in shadi done list!
              </p>
            </>
          )}
        {user?.shadiDone &&
          user?.blocked &&
          user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                You moved this person in blocked list!
              </p>
              <p style={styles.nowAvailableSubText}>
                Sorry this person moved you in shadi done list & blocked list!
              </p>
            </>
          )}
        {!user?.shadiDone &&
          user?.blocked &&
          user?.blockedUserIdByMe &&
          !user?.shadiDoneByMe && (
            <>
              <p style={styles.nowAvailableText}>Not Available</p>
              <p style={styles.nowAvailableSubText}>
                Sorry this person moved you in blocked list!
              </p>
              <p style={styles.nowAvailableSubText}>
                You moved this person in blocked list!
              </p>
            </>
          )}
      </IonCard>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="actionAlert123"
        header="Are you sure you want to remove interest?"
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "alertButton",
          },
          {
            text: "Yes",
            handler: handleRemoveInterest,
            cssClass: "alertButton",
          },
        ]}
      />

      <PopUp
        isOpenPopUp={isOpenPopUp}
        setOpenPopUp={setOpenPopUp}
        img={publish}
        goTo={goTo}
        componentName={"golive"}
        cancel={true}
      />
    </div>
  );
};
export default NewUserCard;
