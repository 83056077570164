import { IonButton, IonCol, IonImg, IonLabel, IonPage, IonRow, useIonRouter } from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import logo from '../../assets/images/logo.png'
import appContext from '../../context/AppContext';
import constant from '../../constant';

const Test: React.FC = () => {

  const history = useHistory();
  const AppContext = useContext(appContext);
  const ionRouter = useIonRouter();
  // console.log("ionRouter :", ionRouter);

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(-1, () => {
      if (history.location.pathname === "/") {
        App.exitApp();
      }
    });
  });

  return (
    <>
      <IonPage>
        <div className='test-background-image'>
          <div className='test-content'>
            <img loading="lazy" style={{ height: 120 }} src={logo} alt='logo-image'></img>
            <div style={{ marginTop: '20px' }}>
              <IonLabel className='test-body-text '>

                <h1><b>Finding Your Best Match Became Easier With Us .</b></h1>
                <p>Join us for seamlessly connect with your prospects and get more responses. </p>
                <IonRow class='ion-margin-top'>
                  <IonCol>
                    <div className='hr-border-red'></div>
                    <div className='hr-border-yellow'></div>
                    <div className='hr-border-green'></div>
                    <div className='hr-border-blue'></div>
                    <div className='hr-text-home'>🙏</div>
                  </IonCol>
                </IonRow>
              </IonLabel>
            </div>
            <div style={{ marginTop: '30px' }} className="test-btn">
              <IonButton color='warning' class='login-button-first-page' onClick={() => { AppContext.setUserStatus({ ...AppContext?.userStatus, form: constant.SignIn }); history.push("/signin") }}>Sign In</IonButton>
              <IonButton color='primary' class='login-button-first-page' onClick={() => { AppContext.setUserStatus({ ...AppContext?.userStatus, form: constant.SignUp }); history.push("/signin") }}>Sign Up</IonButton>
            </div>
          </div>
        </div>
      </IonPage>
    </>
  );
};
export default Test;