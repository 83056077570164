import { IonAccordion, IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonRow, IonSkeletonText, IonThumbnail, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import appContext from "../../context/AppContext"
import constant from '../../constant';
import { useHistory } from 'react-router-dom';
import { getPushNotification, getPushNotification1 } from '../../service';
import subscribenow from '../../assets/images/subs.png'
import PopUp from './PopUp';
import Details from './Details';
import pushNotificationCardLogo from '../../assets/images/pushNotificationCardLogo.png';
import { chevronDownOutline } from 'ionicons/icons';



function Timer({ endTime }: any) {
    const [timeRemaining, setTimeRemaining] = useState<any>(calculateTimeRemaining());

    function calculateTimeRemaining() {
        const now = new Date().getTime();
        const remaining = new Date(endTime).getTime() - now;
        if (remaining <= 0) {
            return "Timer Expired";
        }
        const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remaining / (1000 * 60)) % 60);
        const seconds = Math.floor((remaining / 1000) % 60);
        return { days, hours, minutes, seconds };
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(interval);
    }, [endTime]);

    const formatTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };

    return (
        <div>
            {typeof timeRemaining === 'string' ? (
                <IonLabel className='mx-1' style={{ background: "#ef423b", color: "white" }}>{timeRemaining === "Timer Expired" && ''}</IonLabel>
            ) : (
                <div>
                    <div className="row d-flex justify-content-center mt-1 w-100">
                        <div className="col-2 d-flex flex-column align-items-center timerContainer">
                            <span className='timeDisplay'>{formatTime(timeRemaining.days)}</span>
                            DAYS
                        </div>
                        <div className="col-2 d-flex flex-column align-items-center timerContainer">
                            <span className='timeDisplay'>{formatTime(timeRemaining.hours)}</span>
                            HRS
                        </div>
                        <div className="col-2 d-flex flex-column align-items-center timerContainer">
                            <span className='timeDisplay'>{formatTime(timeRemaining.minutes)}</span>
                            MIN
                        </div>
                        <div className="col-2 d-flex flex-column align-items-center timerContainer">
                            <span className='timeDisplay'>{formatTime(timeRemaining.seconds)}</span>
                            SEC
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const PushNotification: React.FC = () => {
    const AppContext = useContext(appContext);
    const [notifications1, setNotifications1] = useState<any[]>([]);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState<any>(true);

    useIonViewWillEnter(() => {
        getNotification1()
    })

    const handleAccordionClick = () => {
        setAccordionExpanded(!accordionExpanded);
    };


    const getNotification1 = useCallback(async () => {
        const res: any = await getPushNotification1({ "id": AppContext.user.id });
        return res?.data?.data?.excelHistory;
    }, [])

    const getNotification = useCallback(async () => {
        const res: any = await getPushNotification();
        return res?.data?.data?.excelHistory;
    }, [])

    const memoizedData1 = useMemo(async () => {
        return getNotification1();
    }, [getNotification1])

    const memoizedData = useMemo(async () => {
        return getNotification();
    }, [getNotification])

    useEffect(() => {
        Promise.all([
            memoizedData1, memoizedData
        ])
            .then(([notifications, data]) => {
                setNotifications(notifications);
                setNotifications1(data);
            })
            .catch((error) => {
                console.error('Error in handling Promise results:', error);
            });
    }, [memoizedData1, memoizedData]);

    return (
        <>
            <div>
                {notifications.map((notification: any) => {
                    const now = new Date().getTime();
                    const remaining = new Date(notification.timer).getTime() - now;
                    return( <IonCard key={notification?.id} className='card ion-margin-top'>
                        <IonCardContent className='p-2 pt-2'>
                            <IonCardHeader className='p-0 m-0 w-100'>
                                <IonGrid className='mx-0 w-100'>
                                    <IonRow className="mb-2">
                                        <IonCol size="11">
                                            <img style={{ height: "14px", marginRight: "2px" }} src={pushNotificationCardLogo} />
                                            <span style={{ fontSize: "13px", marginLeft: "5px" }}><b>Jain Shadi Milan</b></span>
                                        </IonCol>
                                        <IonCol style={{ background: "#eaeaea", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", borderRadius: "10px" }} size='1'>
                                            <IonIcon
                                                icon={chevronDownOutline}
                                                color="dark"
                                                style={{
                                                    transform: accordionExpanded ? 'rotate(180deg)' : 'none',
                                                    transition: 'transform 0.3s ease-in-out',
                                                }}
                                                onClick={handleAccordionClick}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='mx-0 w-100'>
                                        <IonCol size={`${!(remaining <= 0) ? '7' : '12'}`} className='ion-text-start mx-0 w-100'>
                                            <h3 className="fs-20 fw-bold text-start">
                                                {notification?.heading}
                                            </h3>
                                            <h3 className="fs-16 text-start">
                                                {notification?.sub_heading}
                                            </h3>
                                        </IonCol>
                                        {!(remaining <= 0) && <IonCol className='w-100' size="5">
                                            <Timer endTime={notification.timer} />
                                        </IonCol>}
                                    </IonRow>
                                </IonGrid>
                            </IonCardHeader>
                            {accordionExpanded && (<IonGrid>
                                <IonRow>
                                    {notification?.image && <div style={{ textAlign: "center", borderRadius: "1em", width: "100%" }}>
                                        <img alt="image" src={`${constant.ImageS3URl}/users/push_notification/${notification?.image}`} />
                                    </div>}
                                </IonRow>
                                <IonRow>
                                    <IonCol className="custom-red-button" size='12' style={{ display: "flex", justifyContent: "end" }}>
                                        {JSON.parse(notification?.button).map((btn: any) => {
                                            return (
                                                <IonButton shape='round' href={btn?.textFieldLabelLink}>{btn?.textFieldLabelName}</IonButton>
                                            )
                                        })}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>)}
                        </IonCardContent>
                    </IonCard>)
})}
            </div>
            <div>
                {notifications1?.map((notification: any, i: any) => (
                    <IonCard key={i} className='card ion-margin-top'>
                        <IonCardContent className='p-2 pt-2'>
                            <IonCardHeader className='p-0 m-0 w-100'>
                                <IonGrid className='mx-0 w-100'>
                                    <IonRow className="mb-2">
                                        <IonCol size="11">
                                            <img style={{ height: "14px", marginRight: "2px" }} src={pushNotificationCardLogo} />
                                            <span style={{ fontSize: "13px", marginLeft: "5px" }}><b>Jain Shadi Milan</b></span>
                                        </IonCol>
                                        <IonCol style={{ background: "#eaeaea", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", borderRadius: "10px" }} size='1'>
                                            <IonIcon
                                                icon={chevronDownOutline}
                                                color="dark"
                                                style={{
                                                    transform: accordionExpanded ? 'rotate(180deg)' : 'none',
                                                    transition: 'transform 0.3s ease-in-out',
                                                }}
                                                onClick={handleAccordionClick}
                                            />
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className='mx-0 w-100'>
                                        <IonCol size='7' className='ion-text-start mx-0 w-100'>
                                            <h3 className="fs-20 fw-bold text-start">
                                                {notification?.heading}
                                            </h3>
                                            <h3 className="fs-16 text-start">
                                                {notification?.sub_heading}
                                            </h3>
                                        </IonCol>
                                        {notification?.timer && <IonCol className='w-100' size="5">
                                            <Timer endTime={notification.timer} />
                                        </IonCol>}
                                    </IonRow>
                                </IonGrid>
                            </IonCardHeader>
                            {accordionExpanded && (<IonGrid>
                                <IonRow>
                                    {notification?.image && <div style={{ textAlign: "center", borderRadius: "1em", width: "100%" }}>
                                        <img alt="image" src={`${constant.ImageS3URl}/users/push_notification/${notification?.image}`} />
                                    </div>}
                                </IonRow>
                                <IonRow>
                                    <IonCol className="custom-red-button" size='12' style={{ display: "flex", justifyContent: "end" }}>
                                        {JSON.parse(notification?.button).map((btn: any) => {
                                            return (
                                                <IonButton shape='round' href={btn?.textFieldLabelLink}>{btn?.textFieldLabelName}</IonButton>
                                            )
                                        })}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>)}
                        </IonCardContent>
                    </IonCard>
                ))}
            </div>
        </>

    );
};
export default PushNotification;
