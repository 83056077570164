import React, { useEffect, useState } from 'react'
import { Storage } from '@ionic/storage';

export function useStorage(storageKey: string) {
    const [store, setStore] = useState<Storage>();
    const [userData, setUserData] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        const initStorage = async () => {
            const newStorage = new Storage({
                name: "JSM-cacheData"
            });
            const storeN = await newStorage.create();
            setStore(storeN);
            const storedData = await storeN.get(storageKey) || {};
            setUserData(storedData);
        };
        initStorage();
    }, [storageKey]);

    const updateStorage = async (id: string, newData: any) => {
        if (store) {
            const currentData = userData[id] || {};
            const updatedData = { ...currentData, ...newData };

            if (newData.unlock !== undefined) {
                updatedData.unlock = newData.unlock;
            }

            const updatedUserData = { ...userData, [id]: updatedData };
            await store.set(storageKey, updatedUserData);
            setUserData(updatedUserData);
        }
    };


    return { userData, updateStorage };
}



