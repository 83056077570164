import React, { useContext } from 'react';
import { IonButton, IonModal, IonHeader, IonContent, IonToolbar, IonTitle, IonPage, IonIcon } from '@ionic/react';
import appContext from "../../context/AppContext"
import { closeOutline } from 'ionicons/icons';
import Notification, { MemoizedNotification } from './Notification';
import Packages from './Packages';
import PushNotification from './PushNotification';

function NotificationModal() {

    const AppContext = useContext(appContext);
    const onDidDismiss = async () => {
        AppContext.setNotification(false);
        localStorage.setItem('isModal', 'false')

    }
    const onWillDismiss = async () => {
        AppContext.setNotification(false);
        localStorage.setItem('isModal', 'false')

    }

    return (
        <IonPage>
            {AppContext.notification && <Packages />}
            <IonContent className="ion-padding">
                <IonModal
                    isOpen={AppContext.notification}
                    onWillPresent={() => {
                        localStorage.setItem('isModal', 'true')
                    }}
                    onDidPresent={() => {
                        localStorage.setItem('isModal', 'true')
                    }}
                    onDidDismiss={onDidDismiss}
                    onWillDismiss={onWillDismiss}>

                    <IonHeader>
                        <IonToolbar color={"primary"}>
                            <IonTitle className='heading text-white'>Notification</IonTitle>
                            <div slot="end" className='backBtn'>
                                <IonButton type="submit" className="px-1" onClick={() => AppContext.setNotification(false)}>
                                    <IonIcon icon={closeOutline} color="light" />
                                </IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        {/* <Notification /> */}
                        <MemoizedNotification />
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
}

export default NotificationModal;

export const MemoizedNotificationModal = React.memo(NotificationModal);