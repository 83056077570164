import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import appContext from "../../context/AppContext";
import constant from "../../constant";
import PopUp from "./PopUp";
import payment from "../../assets/images/payment.png";
import { checkmarkOutline, closeCircleOutline } from "ionicons/icons";

interface MatchParams {
  transactionId: string;
  status: string;
}

const PaymentCallBack: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const AppContext = useContext(appContext);
  const { transactionId, status } = match.params;
  const history = useHistory();
  const [countdown, setCountdown] = useState(5);
  const [isOpenPopUp, setOpenPopUp] = useState<any>(false);
  const [prefix, profileId, i] = transactionId.split("-");
  // console.log("i ::::", i);
  useIonViewDidEnter(() => {
    setCountdown(5);
  });

  useEffect(() => {
    const timer: any =
      countdown > 0 &&
      setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

    if (countdown === 0) {
      clearInterval(timer);
      if (status === "true") {
        history.push("/app/profile");
        AppContext.setPopups(constant.popups.isSubscribe, true, true);
        setOpenPopUp(true);
      } else {
        history.push("/app/home");
      }
    }

    return () => clearInterval(timer);
  }, [countdown]);

  const goTo = async () => {
    // setOpenPopUp(false);
    // AppContext.updateUser();
    // if (localStorage.getItem("activeUser")) {
    //   setOpenDetail(true);
    // }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color={"primary"}>
          <div
            onClick={() => {
              // history.goBack();
            }}
            slot="start"
            className="backBtn"
          ></div>
          <IonTitle className="fs-18 text-white">
            <b>
              {status === "true"
                ? "Transaction Completed"
                : "Transaction Failed"}
            </b>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="payment-callback-content">
          {status === "true" ? (
            <div className="success-message">
              <IonIcon icon={checkmarkOutline} className="success-icon" />
              <p className="success-text">Payment Successful!</p>
              <p className="success-amount">₹{i}</p>
            </div>
          ) : (
            <div className="failed-message">
              <IonIcon icon={closeCircleOutline} className="failed-icon" />
              <p className="failed-text">Payment failed!</p>
              <p className="failed-amount">₹{i}</p>
            </div>
          )}
          <div className="transaction-id">Transaction ID: {transactionId}</div>
          <div className="redirect-timer">
            Redirecting in {countdown} seconds...
          </div>
        </div>
        <PopUp
          isOpenPopUp={isOpenPopUp}
          setOpenPopUp={setOpenPopUp}
          img={payment}
          goTo={goTo}
          cancel={false}
          componentName={"payment"}
        />
      </IonContent>
    </IonPage>
  );
};

export default PaymentCallBack;
